import dayjs from "dayjs"

import _c from "../configs/constants"
import type { IAttributionData } from "../server/dao/user_attribution_dao"
import { getDeviceOs, getPageSource, removeTrailingSlash } from "./../utils/browser_util"

class AttributionController {
    public static getPageVisitData = () => {
        const deviceRaw = navigator.userAgent
        const device = getDeviceOs()

        // medium refers to the channel e.g. social, organic, paid, email, affiliate
        let medium = "direct"
        // source refers to individual site within that channel
        let sourceRaw = undefined
        let source = undefined
        let campaign = undefined

        const utmParams = this.getUTMParameters(window.location.href)

        if ("utm_medium" in utmParams) {
            medium = utmParams["utm_medium"]
        }

        if ("utm_source" in utmParams) {
            source = utmParams["utm_source"]
            sourceRaw = document.referrer ?? utmParams["utm_source"]
        } else if (!document.referrer) {
            source = "direct"
            sourceRaw = "direct"
        } else {
            sourceRaw = removeTrailingSlash(document.referrer)
            source = getPageSource(sourceRaw)
        }

        if ("utm_campaign" in utmParams) {
            campaign = utmParams["utm_campaign"]
        }

        return {
            source,
            sourceRaw,
            medium,
            campaign,
            device,
            deviceRaw,
        }
    }

    public static updateUserAttribution = (creatorSlug: string) => {
        const { FIRST_ATTRIBUTION_KEY, FIRST_DATE_KEY, RECENT_ATTRIBUTION_KEY, RECENT_DATE_KEY } =
            _c.LOCAL_STORAGE_KEYS
        const attributionData = { ...this.getPageVisitData(), creatorSlug }

        const hasStoredAttribution = !!localStorage.getItem(FIRST_ATTRIBUTION_KEY)
        if (hasStoredAttribution) {
            let shouldUpdate = true
            const mostRecentDateItem = localStorage.getItem(RECENT_DATE_KEY)
            if (!!mostRecentDateItem) {
                const mostRecentDate = new Date(parseInt(mostRecentDateItem))
                // Update every 12 hours
                shouldUpdate = Math.abs(dayjs().diff(mostRecentDate, "hours")) > 12
            }
            if (shouldUpdate) {
                localStorage.setItem(RECENT_DATE_KEY, `${new Date().getTime()}`)
                localStorage.setItem(RECENT_ATTRIBUTION_KEY, JSON.stringify(attributionData))
            }
        } else {
            localStorage.setItem(FIRST_DATE_KEY, `${new Date().getTime()}`)
            localStorage.setItem(RECENT_DATE_KEY, `${new Date().getTime()}`)
            localStorage.setItem(FIRST_ATTRIBUTION_KEY, JSON.stringify(attributionData))
            localStorage.setItem(RECENT_ATTRIBUTION_KEY, JSON.stringify(attributionData))
        }

        return attributionData
    }

    public static getUserAttribution = () => {
        const firstTouchDate = localStorage.getItem(_c.LOCAL_STORAGE_KEYS.FIRST_DATE_KEY)
        const firstAttribution = localStorage.getItem(_c.LOCAL_STORAGE_KEYS.FIRST_ATTRIBUTION_KEY)
        const recentAttribution = localStorage.getItem(_c.LOCAL_STORAGE_KEYS.RECENT_ATTRIBUTION_KEY)

        return {
            firstDate: (!!firstTouchDate
                ? new Date(parseInt(firstTouchDate))
                : new Date()
            ).toISOString(),
            first: !!firstAttribution ? (JSON.parse(firstAttribution) as IAttributionData) : null,
            mostRecent: !!recentAttribution
                ? (JSON.parse(recentAttribution) as IAttributionData)
                : null,
        }
    }

    public static clearUserAttribution = () => {
        localStorage.removeItem(_c.LOCAL_STORAGE_KEYS.FIRST_DATE_KEY)
        localStorage.removeItem(_c.LOCAL_STORAGE_KEYS.FIRST_ATTRIBUTION_KEY)
        localStorage.removeItem(_c.LOCAL_STORAGE_KEYS.RECENT_ATTRIBUTION_KEY)
    }

    private static getUTMParameters = (url: string): { [key: string]: string } => {
        const searchParams = new URLSearchParams(new URL(url).search)
        const utmParams: { [key: string]: string } = {}

        for (const [key, value] of searchParams) {
            if (key.startsWith("utm_")) {
                utmParams[key] = value
            }
        }

        return utmParams
    }
}

export default AttributionController
