import { stringifyUrl } from "query-string"

import { EActiveSocialPlatform } from "../@types/social_platform_types"
import { ESocialPlatformScheduleLevel } from "../utils/social_util"
import _c from "./constants"

export interface IStaticRoute {
    url: string
    type: ERequestType
    options?: {
        noToken?: boolean
        internal?: boolean
        external?: boolean
        headers?: Record<any, any>
        blobResponse?: boolean
        textResponse?: boolean
    }
}

export enum ERequestType {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
}

export const API_VERSION = 1
const API_BASE_URL = `${_c.BASE_URL}/api/v${API_VERSION}`

const routes = {
    POST_REPORT_PROBLEM: {
        url: `${API_BASE_URL}/report-problem`,
        type: ERequestType.POST,
    },

    // AUTH
    POST_AUTH_REGISTER: {
        url: `${API_BASE_URL}/auth/register`,
        type: ERequestType.POST,
    },
    POST_AUTH_LOGIN: {
        url: `${API_BASE_URL}/auth/login`,
        type: ERequestType.POST,
    },
    POST_AUTH_LOGOUT: {
        url: `${API_BASE_URL}/auth/logout`,
        type: ERequestType.POST,
    },
    POST_AUTH_REGISTER_FIREBASE: {
        url: `${API_BASE_URL}/auth/register-firebase`,
        type: ERequestType.POST,
    },
    POST_AUTH_REGISTER_EXISTING: {
        url: `${API_BASE_URL}/auth/register-existing-user`,
        type: ERequestType.POST,
    },
    GET_USER_AUTH_STATUS: {
        url: `${API_BASE_URL}/auth/status`,
        type: ERequestType.GET,
    },
    POST_SEND_MAGIC_CLAIM: {
        url: `${API_BASE_URL}/auth/send-magic-claim`,
        type: ERequestType.POST,
    },

    // OAUTH
    GET_OAUTH_SOCIAL_PLATFORM_LOGIN: (
        platform: EActiveSocialPlatform,
        userId: string,
        taskId: string,
        snippetId: string,
    ) => ({
        url: stringifyUrl({
            url: `${API_BASE_URL}/oauth/${platform}/login`,
            query: { userId, taskId, snippetId },
        }),
    }),
    POST_OAUTH_SOCIAL_PLATFORM_CREATE_USER: {
        url: `${API_BASE_URL}/oauth/create-user`,
        type: ERequestType.POST,
    },
    POST_OAUTH_SOCIAL_PLATFORM_REVOKE_USER: {
        url: `${API_BASE_URL}/oauth/revoke-user`,
        type: ERequestType.POST,
    },
    GET_OAUTH_SOCIAL_PLATFORM_LOGIN_CALLBACK: (platform: EActiveSocialPlatform) => ({
        url: `${API_BASE_URL}/oauth/${platform}/callback`,
        type: ERequestType.GET,
    }),

    // SOCIAL PLATFORM
    POST_SOCIAL_PLATFORM_SCHEDULE_POST: {
        url: `${API_BASE_URL}/social-platform/schedule-post`,
        type: ERequestType.POST,
    },
    POST_SOCIAL_PLATFORM_EXECUTE_TASK: {
        url: `${API_BASE_URL}/social-platform/execute-task`,
        type: ERequestType.POST,
    },
    POST_SOCIAL_PLATFORM_CANCEL_POST: {
        url: `${API_BASE_URL}/social-platform/cancel-post`,
        type: ERequestType.POST,
    },
    GET_SOCIAL_PLATFORM_SCHEDULE_POST: (level: ESocialPlatformScheduleLevel) => ({
        url: stringifyUrl({
            url: `${API_BASE_URL}/social-platform/schedules`,
            query: { level },
        }),
        type: ERequestType.GET,
    }),
    GET_SOCIAL_PLATFORM_SCHEDULE_STATUS: (taskId?: string) => ({
        url: stringifyUrl({
            url: `${API_BASE_URL}/social-platform/schedule-status`,
            query: { taskId },
        }),
        type: ERequestType.GET,
    }),
    GET_SOCIAL_PLATFORM_CREATOR_INFO: (payload: string) => ({
        url: stringifyUrl({
            url: `${API_BASE_URL}/social-platform/creator`,
            query: { payload },
        }),
        type: ERequestType.GET,
    }),

    // LIVE

    GET_LIVE_ALL: {
        url: `${API_BASE_URL}/live/all`,
        type: ERequestType.GET,
    },
    GET_LIVE_ATTENDEES: {
        url: `${API_BASE_URL}/live/attendees`,
        type: ERequestType.GET,
    },
    GET_LIVE_RECORDING_PURCHASES: {
        url: `${API_BASE_URL}/live/recording-purchases`,
        type: ERequestType.GET,
    },
    GET_LIVE_BY_SLUG: (slug: string) => ({
        url: `${API_BASE_URL}/live/${slug}`,
        type: ERequestType.GET,
    }),
    GET_LIVE_RECORDING_BY_ID: (id: string) => ({
        url: `${API_BASE_URL}/recording/${id}`,
        type: ERequestType.GET,
    }),
    GET_LIVE_CHECK_ATTENDANCE: {
        url: `${API_BASE_URL}/live/check-attendance-purchase`,
        type: ERequestType.GET,
    },
    GET_LIVE_CHECK_RECORDING_PURCHASE: {
        url: `${API_BASE_URL}/live/check-recording-purchase`,
        type: ERequestType.GET,
    },
    GET_LIVE_RECORDING_PURCHASE: {
        url: `${API_BASE_URL}/live/get-recording-purchase`,
        type: ERequestType.GET,
    },
    POST_LIVE_CREATE: {
        url: `${API_BASE_URL}/live/create`,
        type: ERequestType.POST,
    },
    POST_LIVE_UPDATE: {
        url: `${API_BASE_URL}/live/update`,
        type: ERequestType.POST,
    },
    POST_LIVE_DELETE: {
        url: `${API_BASE_URL}/live/delete`,
        type: ERequestType.POST,
    },
    CREATE_ATTENDANCE_CHECKOUT_SESSION: {
        url: `${API_BASE_URL}/live/checkout`,
        type: ERequestType.POST,
    },
    CREATE_COURSE_CHECKOUT_SESSION: {
        url: `${API_BASE_URL}/course/checkout`,
        type: ERequestType.POST,
    },
    CREATE_RECORDING_CHECKOUT_SESSION: {
        url: `${API_BASE_URL}/recording/checkout`,
        type: ERequestType.POST,
    },
    SEND_RECORDING_TOKEN: {
        url: `${API_BASE_URL}/live/send-recording-token`,
        type: ERequestType.POST,
    },
    TRIGGER_RECORDING_EXPIRY: {
        url: `${API_BASE_URL}/recording/trigger-expiry`,
        type: ERequestType.POST,
    },
    GET_LIVE_FEEDBACK_BY_ATTENDEE: (slug: string, attendeeId: string) => ({
        url: `${API_BASE_URL}/live/${slug}/feedback/${attendeeId}`,
        type: ERequestType.GET,
    }),
    POST_LIVE_FEEDBACK: {
        url: `${API_BASE_URL}/live/feedback`,
        type: ERequestType.POST,
    },
    GET_LIVE_FEEDBACK_AVG: {
        url: `${API_BASE_URL}/live/feedback-avg`,
        type: ERequestType.GET,
    },
    GET_LIVE_RECORDING_ALL: {
        url: `${API_BASE_URL}/recording/all`,
        type: ERequestType.GET,
    },
    SEND_RECORDING_TO_SUBSCRIBER: {
        url: `${API_BASE_URL}/recording/send-to-subscriber`,
        type: ERequestType.POST,
    },

    // PAYMENT
    GET_PAYMENTS_DASHBOARD_LINK: {
        url: `${API_BASE_URL}/payment/dashboard-link`,
        type: ERequestType.GET,
    },

    GET_PAYMENTS_RECEIVED: {
        url: `${API_BASE_URL}/payment/received`,
        type: ERequestType.GET,
    },

    // COURSES

    GET_COURSE: {
        url: `${API_BASE_URL}/course`,
        type: ERequestType.GET,
    },
    POST_CREATE_COURSE: {
        url: `${API_BASE_URL}/course/create`,
        type: ERequestType.POST,
    },
    POST_UPDATE_COURSE: {
        url: `${API_BASE_URL}/course/update`,
        type: ERequestType.POST,
    },
    GET_COURSE_PURCHASE: {
        url: `${API_BASE_URL}/course/purchase`,
        type: ERequestType.GET,
    },

    // SNIPPET

    GET_SNIPPET_TASKS: {
        url: `${API_BASE_URL}/snippet/all`,
        type: ERequestType.GET,
    },
    GET_SNIPPET_TASK_BY_ID: (snippetTaskId: string) => ({
        url: `${API_BASE_URL}/snippet/${snippetTaskId}`,
        type: ERequestType.GET,
    }),
    GET_SNIPPET_TASK_BY_SNIPPET_ID: (snippetId: string) => ({
        url: `${API_BASE_URL}/snippet/snippets/${snippetId}`,
        type: ERequestType.GET,
    }),
    GET_SNIPPET_VIDEO: (videoId: string) => ({
        url: `${API_BASE_URL}/snippet/video/${videoId}`,
        type: ERequestType.GET,
    }),
    POST_INITIALISE_SNIPPET_TASK: {
        url: `${API_BASE_URL}/snippet/initialise`,
        type: ERequestType.POST,
    },
    POST_PROCESS_SNIPPETS_YOUTUBE: {
        url: `${API_BASE_URL}/snippet/process/youtube`,
        type: ERequestType.POST,
    },
    POST_PROCESS_SNIPPETS_UPLOAD: {
        url: `${API_BASE_URL}/snippet/process/upload`,
        type: ERequestType.POST,
        options: {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        },
    },
    POST_GET_SNIPPET_PREVIEW: {
        url: `${API_BASE_URL}/snippet/preview`,
        type: ERequestType.POST,
    },
    POST_UPDATE_SNIPPETS: {
        url: `${API_BASE_URL}/snippet/update`,
        type: ERequestType.POST,
    },
    POST_START_SNIPPET_TASK: {
        url: `${API_BASE_URL}/snippet/start`,
        type: ERequestType.POST,
    },
    POST_UPDATE_SNIPPET_VIDEO: {
        url: `${API_BASE_URL}/snippet/snippets/update`,
        type: ERequestType.POST,
    },
    POST_UPDATE_MANY_SNIPPET_VIDEOS: {
        url: `${API_BASE_URL}/snippet/snippets/update-many`,
        type: ERequestType.POST,
    },
    POST_EXPORT_SNIPPET_VIDEO: {
        url: `${API_BASE_URL}/snippet/export`,
        type: ERequestType.POST,
    },
    POST_SNIPPET_VALIDATE_YOUTUBE_VIDEO: {
        url: `${API_BASE_URL}/snippet/validate/youtube`,
        type: ERequestType.POST,
    },
    POST_SNIPPET_DOWNLOAD_TRANSCRIPT: {
        url: `${API_BASE_URL}/snippet/download-transcript`,
        type: ERequestType.POST,
        options: {
            textResponse: true,
        },
    },

    // BRAND STYLE
    GET_USER_BRAND_STYLES: {
        url: `${API_BASE_URL}/brand-style/user`,
        type: ERequestType.GET,
    },
    POST_CREATE_BRAND_STYLE: {
        url: `${API_BASE_URL}/brand-style/create`,
        type: ERequestType.POST,
    },
    POST_UPDATE_BRAND_STYLE: {
        url: `${API_BASE_URL}/brand-style/update`,
        type: ERequestType.POST,
    },

    // USER
    GET_CURRENT_USER: {
        url: `${API_BASE_URL}/user/current`,
        type: ERequestType.GET,
    },
    GET_USER_BY_SLUG: (slug: string) => ({
        url: `${API_BASE_URL}/user/${slug}`,
        type: ERequestType.GET,
    }),
    POST_UPDATE_PROFILE: {
        url: `${API_BASE_URL}/user/update-profile`,
        type: ERequestType.POST,
    },
    POST_REFER_USER: {
        url: `${API_BASE_URL}/user/refer`,
        type: ERequestType.POST,
    },
    GET_USER_VERIFICATION_LINK: {
        url: `${API_BASE_URL}/user/verification-link`,
        type: ERequestType.GET,
    },
    GET_USER_VERIFICATION_NEEDS: {
        url: `${API_BASE_URL}/user/verification-needs`,
        type: ERequestType.GET,
    },
    POST_VERIFY_USER_EMAIL: {
        url: `${API_BASE_URL}/user/verify-email`,
        type: ERequestType.POST,
    },
    POST_RESEND_VERIFY_USER_EMAIL: {
        url: `${API_BASE_URL}/user/resend-verify-email`,
        type: ERequestType.POST,
    },
    POST_JOIN_WAITING_LIST: {
        url: `${API_BASE_URL}/user/waiting-list`,
        type: ERequestType.POST,
    },
    POST_ACTIVATE_CREATORSHIP: {
        url: `${API_BASE_URL}/user/activate-creatorship`,
        type: ERequestType.POST,
    },
    POST_COMPLETE_REGISTRATION: {
        url: `${API_BASE_URL}/user/complete-registration`,
        type: ERequestType.POST,
    },
    POST_USER_CREATE_SUBSCRIPTION_PLAN: {
        url: `${API_BASE_URL}/user/create-subscription-plan`,
        type: ERequestType.POST,
    },
    GET_USER_SUBSCRIPTION_PLAN: {
        url: `${API_BASE_URL}/user/subscription-plan`,
        type: ERequestType.GET,
    },
    POST_USER_UPDATE_SUBSCRIPTION_PLAN: {
        url: `${API_BASE_URL}/user/update-subscription-plan`,
        type: ERequestType.POST,
    },
    GET_USER_SUBSCRIPTION_PLAN_SUBSCRIBERS: {
        url: `${API_BASE_URL}/user/subscribers`,
        type: ERequestType.GET,
    },
    GET_USER_FEED: {
        url: `${API_BASE_URL}/user/feed`,
        type: ERequestType.GET,
    },
    POST_VALIDATE_MAGIC_CLAIM_TOKEN: {
        url: `${API_BASE_URL}/auth/validate-magic-claim-token`,
        type: ERequestType.POST,
    },
    GET_USER_CREATOR_STATS: {
        url: `${API_BASE_URL}/user/dashboard/stats`,
        type: ERequestType.GET,
    },

    // TRACK

    TRACK_SUBSCRIPTION_CONVERSION: {
        url: `${API_BASE_URL}/track/subscription-conversion`,
        type: ERequestType.POST,
    },
    TRACK_PROFILE_VISIT: {
        url: `${API_BASE_URL}/track/profile-visit`,
        type: ERequestType.POST,
    },
    TRACK_USER_ATTRIBUTION: {
        url: `${API_BASE_URL}/track/user/attribution`,
        type: ERequestType.POST,
    },
    TRACK_DRIP_TAG_USER: {
        url: `${API_BASE_URL}/track/drip/tag-user`,
        type: ERequestType.POST,
    },
    TRACK_DRIP_UNTAG_USER: {
        url: `${API_BASE_URL}/track/drip/untag-user`,
        type: ERequestType.POST,
    },

    // CREATOR

    GET_CREATOR_PROFILE: (slug: string) => ({
        url: `${API_BASE_URL}/creator/${slug}`,
        type: ERequestType.GET,
    }),
    CREATE_CREATOR_SUBSCRIPTION_CHECKOUT: {
        url: `${API_BASE_URL}/creator/subscription/checkout`,
        type: ERequestType.POST,
    },
    GET_CHECK_USER_SUBSCRIBED: {
        url: `${API_BASE_URL}/creator/subscription/check`,
        type: ERequestType.GET,
    },
    GET_CREATOR_SUBSCRIPTION_PLAN: {
        url: `${API_BASE_URL}/creator/subscription/plan`,
        type: ERequestType.GET,
    },
    GET_CREATOR_MEMBERS: {
        url: `${API_BASE_URL}/creator/members`,
        type: ERequestType.GET,
    },

    // SUBSCRIPTION

    GET_LL_SUBSCRIPTION_PLANS: {
        url: `${API_BASE_URL}/platform-subscription-plans`,
        type: ERequestType.GET,
    },
    CREATE_LL_SUBSCRIPTION_CHECKOUT: {
        url: `${API_BASE_URL}/purchase-subscription`,
        type: ERequestType.POST,
    },
    GET_LL_SUBSCRIPTION_PLAN: {
        url: `${API_BASE_URL}/platform-subscription-plan`,
        type: ERequestType.GET,
    },
    POST_COMPLETE_LL_SUBSCRIPTION_PURCHASE: {
        url: `${API_BASE_URL}/purchase-subscription/complete`,
        type: ERequestType.POST,
    },
    POST_CREATE_CUSTOMER_PORTAL_SESSION: {
        url: `${API_BASE_URL}/platform-subscription/customer-portal`,
        type: ERequestType.POST,
    },
    POST_CREATE_MINUTES_TOKEN: {
        url: `${API_BASE_URL}/platform-subscription/create-minutes-token`,
        type: ERequestType.POST,
    },
    POST_ADD_MINUTES: {
        url: `${API_BASE_URL}/platform-subscription/add-minutes`,
        type: ERequestType.POST,
    },
    POST_APPLY_CANCELLATION_DISCOUNT: {
        url: `${API_BASE_URL}/platform-subscription/apply-cancellation-discount`,
        type: ERequestType.POST,
    },
    POST_SWITCH_SUBSCRIPTION_INTERVAL: {
        url: `${API_BASE_URL}/platform-subscription/switch-interval`,
        type: ERequestType.POST,
    },
    POST_CANCEL_SUBSCRIPTION: {
        url: `${API_BASE_URL}/platform-subscription/cancel`,
        type: ERequestType.POST,
    },

    // COMMUNITY

    GET_COMMUNITY_EXISTS: (creatorSlug: string) => ({
        url: `${API_BASE_URL}/community/${creatorSlug}/exists`,
        type: ERequestType.GET,
    }),

    GET_COMMUNITY_METADATA: (creatorSlug: string) => ({
        url: `${API_BASE_URL}/community/${creatorSlug}/metadata`,
        type: ERequestType.GET,
    }),

    GET_COMMUNITY_ALL_ACTIVITY: (creatorSlug: string) => ({
        url: `${API_BASE_URL}/community/${creatorSlug}/all-activity`,
        type: ERequestType.GET,
    }),

    GET_COMMUNITY_CHANNEL_POSTS: (creatorSlug: string, channelSlug: string) => ({
        url: `${API_BASE_URL}/community/${creatorSlug}/channel/${channelSlug}/posts`,
        type: ERequestType.GET,
    }),

    GET_COMMUNITY_CHANNEL: (creatorSlug: string, channelSlug: string) => ({
        url: `${API_BASE_URL}/community/${creatorSlug}/channel/${channelSlug}`,
        type: ERequestType.GET,
    }),

    GET_COMMUNITY_POST: (postId: string) => ({
        url: `${API_BASE_URL}/community/post/${postId}`,
        type: ERequestType.GET,
    }),

    GET_COMMUNITY_POST_COMMENTS: (postId: string) => ({
        url: `${API_BASE_URL}/community/post/${postId}/comments`,
        type: ERequestType.GET,
    }),

    POST_CREATE_COMMUNITY: {
        url: `${API_BASE_URL}/community/create`,
        type: ERequestType.POST,
    },

    POST_PIN_POST_TO_CHANNEL: {
        url: `${API_BASE_URL}/community/channel/pin-post`,
        type: ERequestType.POST,
    },

    UNPIN_POST_FROM_CHANNEL: {
        url: `${API_BASE_URL}/community/channel/unpin-post`,
        type: ERequestType.POST,
    },

    POST_LIKE_POST: {
        url: `${API_BASE_URL}/community/post/like`,
        type: ERequestType.POST,
    },

    POST_LIKE_COMMENT: {
        url: `${API_BASE_URL}/community/comment/like`,
        type: ERequestType.POST,
    },

    POST_CREATE_POST: {
        url: `${API_BASE_URL}/community/post/create`,
        type: ERequestType.POST,
    },

    POST_CREATE_COMMENT: {
        url: `${API_BASE_URL}/community/comment/create`,
        type: ERequestType.POST,
    },

    POST_REPLY_TO_COMMENT: {
        url: `${API_BASE_URL}/community/comment/reply`,
        type: ERequestType.POST,
    },

    POST_CREATE_CHANNEL: {
        url: `${API_BASE_URL}/community/channel/create`,
        type: ERequestType.POST,
    },

    POST_UPDATE_CHANNEL: {
        url: `${API_BASE_URL}/community/channel/update`,
        type: ERequestType.POST,
    },

    DELETE_CHANNEL: {
        url: `${API_BASE_URL}/community/channel/delete`,
        type: ERequestType.DELETE,
    },

    DELETE_POST: {
        url: `${API_BASE_URL}/community/post/delete`,
        type: ERequestType.DELETE,
    },

    POST_EDIT_POST: {
        url: `${API_BASE_URL}/community/post/edit`,
        type: ERequestType.POST,
    },

    POST_CREATE_LINK: {
        url: `${API_BASE_URL}/community/link/create`,
        type: ERequestType.POST,
    },

    POST_INVITE_MEMBER: {
        url: `${API_BASE_URL}/community/invite-member`,
        type: ERequestType.POST,
    },

    POST_CREATE_COMMUNITY_FILE: {
        url: `${API_BASE_URL}/community/create-file`,
        type: ERequestType.POST,
    },

    POST_UPDATE_COMMUNITY_FILE: {
        url: `${API_BASE_URL}/community/update-file`,
        type: ERequestType.POST,
    },

    POST_JOIN_COMMUNITY_MEMBERSHIP: (creatorSlug: string) => ({
        url: `${API_BASE_URL}/community/${creatorSlug}/membership/join`,
        type: ERequestType.POST,
    }),

    DELETE_LEAVE_COMMUNITY_MEMBERSHIP: (creatorSlug: string) => ({
        url: `${API_BASE_URL}/community/${creatorSlug}/membership/leave`,
        type: ERequestType.DELETE,
    }),

    GET_CHECK_COMMUNITY_MEMBERSHIP: (creatorSlug: string) => ({
        url: `${API_BASE_URL}/community/${creatorSlug}/membership/check`,
        type: ERequestType.GET,
    }),

    GET_TRIGGER_TEST: {
        url: `${API_BASE_URL}/trigger/test`,
        type: ERequestType.GET,
    },
}

export default routes
