import routes from "../configs/routes"
import {
    GetPaymentDashboardLinkQuery,
    GetPaymentDashboardLinkResponse,
} from "../pages/api/v1/payment/dashboard-link"
import { GetUserBySlugResponse } from "../pages/api/v1/user/[slug]"
import { PostActivateCreatorshipResponse } from "../pages/api/v1/user/activate-creatorship"
import {
    PostCompleteRegistrationBody,
    PostCompleteRegistrationResponse,
} from "../pages/api/v1/user/complete-registration"
import {
    PostCreateSubscriptionPlanBody,
    PostCreateSubscriptionPlanResponse,
} from "../pages/api/v1/user/create-subscription-plan"
import { GetCurrentUserResponse } from "../pages/api/v1/user/current"
import { GetUserDashboardStatsResponse } from "../pages/api/v1/user/dashboard/stats"
import { GetUserFeedResponse } from "../pages/api/v1/user/feed"
import { PostReferUser, PostReferUserResponse } from "../pages/api/v1/user/refer"
import { GetCreatorSubscribersResponse } from "../pages/api/v1/user/subscribers"
import {
    GetUserSubscriptionPlanQuery,
    GetUserSubscriptionPlanResponse,
} from "../pages/api/v1/user/subscription-plan"
import {
    PostUpdateProfileBody,
    PostUpdateProfileResponse,
} from "../pages/api/v1/user/update-profile"
import {
    PostUpdateSubscriptionPlanBody,
    PostUpdateSubscriptionPlanResponse,
} from "../pages/api/v1/user/update-subscription-plan"
import {
    GetVerificationLinkQuery,
    GetVerificationLinkResponse,
} from "../pages/api/v1/user/verification-link"
import {
    GetVerificationNeedsQuery,
    GetVerificationNeedsResponse,
} from "../pages/api/v1/user/verification-needs"
import { PostVerifyUserEmail, PostVerifyUserEmailResponse } from "../pages/api/v1/user/verify-email"
import { PostWaitingListBody, PostWaitingListResponse } from "../pages/api/v1/user/waiting-list"
import { handleRequest } from "../utils/network_util"
import { PostReportProblemBody, PostReportProblemResponse } from "../pages/api/v1/report-problem"

const userService = (hr = handleRequest) => ({
    getCurrentUser: (): Promise<GetCurrentUserResponse> => hr(routes.GET_CURRENT_USER),

    getUserBySlug: (slug: string): Promise<GetUserBySlugResponse> =>
        hr(routes.GET_USER_BY_SLUG(slug)),

    postUpdateUserProfile: (body: PostUpdateProfileBody): Promise<PostUpdateProfileResponse> =>
        hr(routes.POST_UPDATE_PROFILE, body),

    postReferUser: (body: PostReferUser): Promise<PostReferUserResponse> =>
        hr(routes.POST_REFER_USER, body),

    getVerificationLink: (query: GetVerificationLinkQuery): Promise<GetVerificationLinkResponse> =>
        hr(routes.GET_USER_VERIFICATION_LINK, query),

    getDashboardLink: (
        query: GetPaymentDashboardLinkQuery,
    ): Promise<GetPaymentDashboardLinkResponse> => hr(routes.GET_PAYMENTS_DASHBOARD_LINK, query),

    getVerificationNeeds: (
        query: GetVerificationNeedsQuery,
    ): Promise<GetVerificationNeedsResponse> => hr(routes.GET_USER_VERIFICATION_NEEDS, query),

    postVerifyUserEmail: (body: PostVerifyUserEmail): Promise<PostVerifyUserEmailResponse> =>
        hr(routes.POST_VERIFY_USER_EMAIL, body),

    postJoinWaitingList: (body: PostWaitingListBody): Promise<PostWaitingListResponse> =>
        hr(routes.POST_JOIN_WAITING_LIST, body),

    postActivateCreatorship: (): Promise<PostActivateCreatorshipResponse> =>
        hr(routes.POST_ACTIVATE_CREATORSHIP),

    postCompleteRegistration: (
        body: PostCompleteRegistrationBody,
    ): Promise<PostCompleteRegistrationResponse> => hr(routes.POST_COMPLETE_REGISTRATION, body),

    postUpdateSubscriptionPlan: (
        body: PostUpdateSubscriptionPlanBody,
    ): Promise<PostUpdateSubscriptionPlanResponse> =>
        hr(routes.POST_USER_UPDATE_SUBSCRIPTION_PLAN, body),

    postCreateSubscriptionPlan: (
        body: PostCreateSubscriptionPlanBody,
    ): Promise<PostCreateSubscriptionPlanResponse> =>
        hr(routes.POST_USER_CREATE_SUBSCRIPTION_PLAN, body),

    getUserSubscriptionPlan: (
        query: GetUserSubscriptionPlanQuery,
    ): Promise<GetUserSubscriptionPlanResponse> => hr(routes.GET_USER_SUBSCRIPTION_PLAN, query),

    getUserSubscribers: (): Promise<GetCreatorSubscribersResponse> =>
        hr(routes.GET_USER_SUBSCRIPTION_PLAN_SUBSCRIBERS),

    getUserFeed: (): Promise<GetUserFeedResponse> => hr(routes.GET_USER_FEED),

    getUserStats: (): Promise<GetUserDashboardStatsResponse> => hr(routes.GET_USER_CREATOR_STATS),
    
    postReportProblem: (data: PostReportProblemBody): Promise<PostReportProblemResponse> => hr(routes.POST_REPORT_PROBLEM, data),
})

export default userService
