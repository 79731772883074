import _c from "../configs/constants"

const Drip = () => (
    <script
        async
        type="text/javascript"
        id="drip"
        dangerouslySetInnerHTML={{
            __html: `var _dcq = _dcq || [];
            var _dcs = _dcs || {};
            _dcs.account = "${process.env.NEXT_PUBLIC_DRIP_ACCOUNT_ID}";
            (function() {
              var dc = document.createElement('script');
              dc.type = 'text/javascript';
              dc.async = true;
              dc.src = '//tag.getdrip.com/${process.env.NEXT_PUBLIC_DRIP_ACCOUNT_ID}.js';
              var s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(dc, s);
            })();`,
        }}
    />
)

export default Drip
