import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import absoluteUrl from "next-absolute-url"
import { init } from "next-firebase-auth"

import { linkToCentralRedirect } from "../utils/link_util"
import _c from "./constants"
import { API_VERSION } from "./routes"

// TODO: Update firebase firestore security rules
// TODO: requiring this client-side exposes admin access
const serviceAccount = !_c.isProduction
    ? _c.isTest
        ? require("./credentials/firebase-sa-test.json")
        : require("./credentials/firebase-sa-staging.json")
    : require("./credentials/firebase-sa-prod.json")

const initApp = async () => {
    initNextFirebaseAuth()
    initDayJs()
}

const initDayJs = () => {
    dayjs.extend(advancedFormat)
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.extend(customParseFormat)
    dayjs.extend(relativeTime)
    dayjs.extend(duration)
}

const initNextFirebaseAuth = async () => {
    await init({
        // The default URL to navigate to when withUser or withUserTokenSSR need to redirect to login.
        authPageURL: ({ ctx }) => {
            const isServerSide = typeof window === "undefined"
            const origin = isServerSide ? absoluteUrl(ctx.req).origin : window.location.origin
            const destPath = isServerSide ? ctx.resolvedUrl : window.location.href
            const destURL = new URL(destPath, origin).toString()

            return `/login?destination=${encodeURIComponent(destURL)}`
        },
        // The default URL to navigate to when withUser or withUserTokenSSR need to redirect to the app.
        appPageURL: ({ ctx }) => {
            const isServerSide = typeof window === "undefined"
            const origin = isServerSide ? absoluteUrl(ctx.req).origin : window.location.origin

            const params = isServerSide
                ? new URL(ctx.req.url ?? "", origin).searchParams
                : new URLSearchParams(window.location.search)

            const destinationParamVal = params.get("destination")
                ? decodeURIComponent(params.get("destination")!)
                : undefined

            return linkToCentralRedirect(destinationParamVal)
        },
        loginAPIEndpoint: `/api/v${API_VERSION}/auth/login`,
        logoutAPIEndpoint: `/api/v${API_VERSION}/auth/logout`,
        onLoginRequestError: (err: any) => console.error(err),
        onLogoutRequestError: (err: any) => console.error(err),
        firebaseAuthEmulatorHost: process.env.FIREBASE_AUTH_EMULATOR_HOST ?? undefined,
        firebaseAdminInitConfig: {
            credential: serviceAccount as any,
            databaseURL: process.env.FIREBASE_DATABASE_URL!,
        },
        firebaseClientInitConfig: {
            apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY!,
            authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN!,
            projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID!,
            storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET!,
        },
        cookies: {
            name: "livelink",
            keys: [process.env.COOKIE_SECRET],
            maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
            sameSite: "strict",
            secure: _c.isProductionOrStaging,
            signed: true,
        },
        onVerifyTokenError: (err: any) => {
            console.error(err)
        },
        onTokenRefreshError: (err: any) => {
            console.error(err)
        },
    })
}

export default initApp
