import { PostUpdateUserAttributionBody } from '../pages/api/v1/track/user/attribution';
import routes from "../configs/routes"
import { PostDripTagUserBody } from "../pages/api/v1/track/drip/tag-user"
import { PostDripUntagUserBody } from "../pages/api/v1/track/drip/untag-user"
import { PostTrackPageVisitBody } from "../pages/api/v1/track/profile-visit"
import { handleRequest } from "../utils/network_util"

const trackingService = (hr = handleRequest) => ({
    trackSubscriptionConversion: (body: Record<string, any>): Promise<any> =>
        hr(routes.TRACK_SUBSCRIPTION_CONVERSION, body),

    trackDripAddTagUser: (body: PostDripTagUserBody): Promise<any> =>
        hr(routes.TRACK_DRIP_TAG_USER, body),
    
    trackUserAttribution: (body: PostUpdateUserAttributionBody): Promise<any> =>
        hr(routes.TRACK_USER_ATTRIBUTION, body),

    trackDripRemoveTagUser: (body: PostDripUntagUserBody): Promise<any> =>
        hr(routes.TRACK_DRIP_UNTAG_USER, body),

    trackProfileVisit: (body: PostTrackPageVisitBody): Promise<any> =>
        hr(routes.TRACK_PROFILE_VISIT, body),
})

export default trackingService
