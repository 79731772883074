import { PostCreateBrandStyleBody, PostCreateBrandStyleResponse } from './../pages/api/v1/brand-style/create';
import routes from "../configs/routes"
import {
    PostUpdateBrandStyleBody,
    PostUpdateBrandStyleResponse,
} from "../pages/api/v1/brand-style/update"
import { handleRequest } from "../utils/network_util"
import { GetBrandStylesForUserQuery, GetBrandStylesForUserResponse } from '../pages/api/v1/brand-style/user';

const brandStyleService = (hr = handleRequest) => ({
    getUserBrandStyles: (body: GetBrandStylesForUserQuery): Promise<GetBrandStylesForUserResponse> =>
        hr(routes.GET_USER_BRAND_STYLES),
    postCreateBrandStyle: (body: PostCreateBrandStyleBody): Promise<PostCreateBrandStyleResponse> =>
        hr(routes.POST_CREATE_BRAND_STYLE, body),
    postUpdateBrandStyle: (body: PostUpdateBrandStyleBody): Promise<PostUpdateBrandStyleResponse> =>
        hr(routes.POST_UPDATE_BRAND_STYLE, body),
})

export default brandStyleService
