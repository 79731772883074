import routes from "../configs/routes"
import { GetLiveAllQuery, GetLiveAllResponse } from "../pages/api/v1/live/all"
import {
    CreateAttendanceCheckoutSessionBody,
    CreateAttendanceCheckoutSessionResponse,
} from "../pages/api/v1/live/checkout"
import { GetLiveAttendeesQuery, GetLiveAttendeesResponse } from "../pages/api/v1/live/attendees"
import {
    GetLiveCheckAttendanceQuery,
    GetLiveCheckAttendanceResponse,
} from "../pages/api/v1/live/check-attendance-purchase"
import { PostCreateLiveBody, PostCreateLiveResponse } from "../pages/api/v1/live/create"
import { PostLiveFeedbackBody, PostLiveFeedbackResponse } from "../pages/api/v1/live/feedback"
import {
    GetLiveFeedbackAvgQuery,
    GetLiveFeedbackAvgResponse,
} from "../pages/api/v1/live/feedback-avg"
import {
    GetLiveRecordingPurchasesQuery,
    GetLiveRecordingPurchasesResponse,
} from "../pages/api/v1/live/recording-purchases"
import { PostUpdateLiveBody, PostUpdateLiveResponse } from "../pages/api/v1/live/update"
import { GetLiveBySlugResponse } from "../pages/api/v1/live/[slug]"
import { GetLiveFeedbackByAttendeeResponse } from "../pages/api/v1/live/[slug]/feedback/[attendeeId]"
import { handleRequest } from "../utils/network_util"
import { PostDeleteLiveBody, PostDeleteLiveResponse } from "../pages/api/v1/live/delete"

const liveService = (hr = handleRequest) => ({
    getLiveAll: (query: GetLiveAllQuery): Promise<GetLiveAllResponse> =>
        hr(routes.GET_LIVE_ALL, query),

    getLiveAttendees: (query: GetLiveAttendeesQuery): Promise<GetLiveAttendeesResponse> =>
        hr(routes.GET_LIVE_ATTENDEES, query),

    getLiveRecordingPurchasers: (
        query: GetLiveRecordingPurchasesQuery,
    ): Promise<GetLiveRecordingPurchasesResponse> => hr(routes.GET_LIVE_RECORDING_PURCHASES, query),

    getLiveBySlug: (slug: string): Promise<GetLiveBySlugResponse> =>
        hr(routes.GET_LIVE_BY_SLUG(slug)),

    getLiveCheckAttendancePurchase: (
        query: GetLiveCheckAttendanceQuery,
    ): Promise<GetLiveCheckAttendanceResponse> => hr(routes.GET_LIVE_CHECK_ATTENDANCE, query),

    postCreateLive: (body: PostCreateLiveBody): Promise<PostCreateLiveResponse> =>
        hr(routes.POST_LIVE_CREATE, body),

    postUpdateLive: (body: PostUpdateLiveBody): Promise<PostUpdateLiveResponse> =>
        hr(routes.POST_LIVE_UPDATE, body),

    deleteLive: (body: PostDeleteLiveBody): Promise<PostDeleteLiveResponse> =>
        hr(routes.POST_LIVE_DELETE, body),

    createAttendanceCheckoutSession: (
        body: CreateAttendanceCheckoutSessionBody,
    ): Promise<CreateAttendanceCheckoutSessionResponse> =>
        hr(routes.CREATE_ATTENDANCE_CHECKOUT_SESSION, body),

    getLiveFeedbackByAttendee: (
        liveId: string,
        attendee: string,
    ): Promise<GetLiveFeedbackByAttendeeResponse> =>
        hr(routes.GET_LIVE_FEEDBACK_BY_ATTENDEE(liveId, attendee)),

    postLiveFeedback: (body: PostLiveFeedbackBody): Promise<PostLiveFeedbackResponse> =>
        hr(routes.POST_LIVE_FEEDBACK, body),

    getLiveFeedbackAvg: (query: GetLiveFeedbackAvgQuery): Promise<GetLiveFeedbackAvgResponse> =>
        hr(routes.GET_LIVE_FEEDBACK_AVG, query),
})

export default liveService
