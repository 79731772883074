import { Box, InputProps, SlideFade, Text } from "@chakra-ui/react"
import React from "react"

interface InputHeaderProps extends InputProps {
    title: string
    isVisible: boolean
    left?: string
}

export const InputHeader = ({ title, isVisible, left, ...rest }: InputHeaderProps) => {
    return (
        // @ts-ignore 
        <Box
            pos="absolute"
            top={2}
            left={left ?? "var(--default-input-start-padding)"}
            zIndex={99}
            pointerEvents="none"
            {...rest}
        >
            <SlideFade in={isVisible}>
                <Text fontSize={10} color="blackAlpha.600">
                    {title}
                </Text>
            </SlideFade>
        </Box>
    )
}
