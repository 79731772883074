import { Heading, ModalBody, ModalHeader, Text } from "@chakra-ui/react"
import { useImperativeHandle, useMemo, useState } from "react"
import useAsyncEffect from "use-async-effect"

import { AE } from "../../@types/analytics"
import { EPlatformSubscriptionPlan } from "../../@types/subscription_types"
import _c from "../../configs/constants"
import Analytics from "../../controllers/analytics_controller"
import useWindowSize from "../../hooks/useWindowSize"
import { BasePlatformSubscriptionPlan } from "../../server/dao/platform_subscription_plan_dao"
import { BaseUser } from "../../server/dao/user_dao"
import api from "../../services/root_service"
import { getUserSubscriptionVersion } from "../../utils/subscription_util"
import Modal from "../Modal"
import PricingSection from "../PricingSection"

export interface IPlatformSubscriptionModalProps {
    currentUser?: BaseUser
    modalRef: React.Ref<IPlatformSubscriptionModalRef>
}

export interface IPlatformSubscriptionModalRef {
    open: () => void
    close: () => void
}

const PlatformSubscriptionModal = ({ currentUser, modalRef }: IPlatformSubscriptionModalProps) => {
    const [isVisible, setIsVisible] = useState(false)

    const [plans, setPlans] = useState<BasePlatformSubscriptionPlan[]>([])

    const { width } = useWindowSize()
    const isMobile = !!(width && width < 768)

    const plansHaveTrial =
        plans.length > 0 && plans.some((plan) => (plan.trialPeriodDays! ?? 0) > 0)

    const totalFreeDays = plansHaveTrial && plans[0].trialPeriodDays

    const title = useMemo(() => {
        if (plansHaveTrial && !currentUser?.platformSubscriptions.length) {
            return `Try free for ${totalFreeDays} days`
        } else if (!!currentUser?.platformSubscriptions.length) {
            return "Subscribe to a different plan"
        } else {
            return "Discover your perfect plan"
        }
    }, [plansHaveTrial, currentUser?.platformSubscriptions])

    const modalStyles = () => {
        if (isMobile) {
            return {
                top: "350px",
                bottom: "50px",
            }
        }
    }

    const handleCloseModal = () => {
        setIsVisible(false)
        Analytics.trackEvent(AE.AISnippet_ClosePaywallModal)
    }

    useImperativeHandle(
        modalRef,
        () => ({
            open: () => setIsVisible(true),
            close: () => setIsVisible(false),
        }),
        [],
    )

    useAsyncEffect(async (isMounted) => {
        if (!isMounted()) {
            return
        }
        const { plans } = await api().getLLSubscriptionPlans({
            planType: EPlatformSubscriptionPlan.Snippets,
            version: getUserSubscriptionVersion(currentUser),
        })
        setPlans(plans)
    }, [])

    return (
        <Modal
            size="4xl"
            onDismiss={handleCloseModal}
            isOpen={isVisible}
            canDismiss
            blockScrollOnMount={true}
            contentContainerStyles={modalStyles()}
        >
            <ModalHeader mt={8} ml="20px">
                <Heading as="h1" fontSize="4xl" fontWeight="bold">
                    {title}
                    <Text
                        as="span"
                        display="block"
                        fontWeight="400"
                        fontSize="16px"
                        paddingTop="10px"
                    >
                        {`We've simplified pricing to help you find your
                        ideal fit`}
                    </Text>
                </Heading>
            </ModalHeader>
            <ModalBody>
                <PricingSection plans={plans} currentUser={currentUser} />
            </ModalBody>
        </Modal>
    )
}

export default PlatformSubscriptionModal
// export default forwardRef(_PlatformSubscriptionModal)
// export default function WrappedPlatformSubscriptionModal({
//     modalRef,
//     ...props
// }: IPlatformSubscriptionModalProps & { modalRef: React.Ref<IPlatformSubscriptionModalRef> }) {
//     // @ts-ignore
//     return <PlatformSubscriptionModal {...props} ref={modalRef} />
// }
