import { Box, Button, ModalBody, ModalFooter, ModalHeader, Text } from "@chakra-ui/react"
import dynamic from "next/dynamic"
import {
    forwardRef,
    ForwardRefRenderFunction,
    useEffect,
    useImperativeHandle,
    useState,
} from "react"

import AlertController, { IAlertOptions } from "../controllers/alert_controller"
import Modal from "./Modal"

const LottiePlayer = dynamic(async () => import("./LottiePlayer"), { ssr: false })

export interface IAlertModalControllerRef {
    initialize: (() => void) | null
}

const AlertModal: ForwardRefRenderFunction<IAlertModalControllerRef, object> = (_, ref) => {
    const [isVisible, setIsVisible] = useState(false)
    const [options, setOptions] = useState<IAlertOptions>()
    const modalSize = options?.size ?? "sm"
    const direction = options?.buttonDirection ?? "row"
    const isDarkMode = options?.variant === "dark"

    const showWithOptions = async (_options: IAlertOptions) => {
        if (!!options) {
            throw new Error("AlertModal: showWithOptions: already showing an alert")
        }
        setOptions(_options)
        setIsVisible(true)
    }

    const hideModal = () => {
        setIsVisible(false)
    }

    useEffect(() => {
        AlertController._setControls(showWithOptions, hideModal)
    }, [])

    useImperativeHandle(ref, () => ({
        initialize: () => {
            AlertController._setControls(showWithOptions, hideModal)
        },
    }))

    const handleDismissModal = () => {
        if (options?.onDismiss) {
            options?.onDismiss()
        }
        setIsVisible(false)
    }

    const handleClickPrimaryAction = () => {
        if (options?.primaryAction?.onClick) {
            options?.primaryAction?.onClick()
        }
        hideModal()
        setOptions(undefined)
    }

    const handleClickSecondaryAction = () => {
        if (options?.secondaryAction?.onClick) {
            options?.secondaryAction?.onClick()
        }
        hideModal()
        setOptions(undefined)
    }

    const renderContent = () => {
        if (!options) {
            return null
        }

        const variantColor = (() => {
            if (options?.variant === "danger") {
                return "red.600"
            }
            if (isDarkMode) {
                return "white"
            }
        })()

        const bgColor = isDarkMode ? "#060B1A" : undefined
        const { title, message, primaryAction, secondaryAction } = options
        return (
            <>
                <ModalHeader
                    display="flex"
                    paddingTop={7}
                    paddingBottom={2}
                    paddingX={7}
                    fontSize="2xl"
                    flexDirection="column"
                    color={variantColor}
                    backgroundColor={bgColor}
                >
                    {options.icon}
                    {title}
                </ModalHeader>
                {message !== null && (
                    <ModalBody px={7} bgColor={bgColor}>
                        <Text whiteSpace="pre-line" color={isDarkMode ? "white" : "black"}>
                            {message}
                        </Text>
                    </ModalBody>
                )}
                <ModalFooter pb={7} px={7} bgColor={bgColor} flexDirection={direction} gap={3}>
                    {secondaryAction && (
                        <Button
                            onClick={handleClickSecondaryAction}
                            colorScheme={"gray"}
                            bgColor={isDarkMode ? "#262B38" : undefined}
                            width="full"
                            size="lg"
                            _hover={isDarkMode ? { bgColor: "#ffffff46" } : undefined}
                        >
                            {secondaryAction.label}
                        </Button>
                    )}
                    <Button
                        onClick={handleClickPrimaryAction}
                        variant="primary"
                        width="full"
                        size="lg"
                        backgroundColor={variantColor}
                        color={isDarkMode ? "#060B1A" : undefined}
                        _hover={isDarkMode ? { bgColor: "#ffffff76" } : undefined}
                    >
                        {primaryAction.label}
                    </Button>
                </ModalFooter>
                {options.animation && (
                    <Box
                        position="fixed"
                        top="calc(50vh - (600px / 2))"
                        left="calc(50vw - (600px / 2))"
                        pointerEvents="none"
                    >
                        <LottiePlayer
                            animation={options.animation}
                            style={{
                                width: "600px",
                                height: "600px",
                            }}
                            loop={false}
                            autoplay
                        />
                    </Box>
                )}
            </>
        )
    }

    return (
        <Modal
            isOpen={isVisible}
            canDismiss={false || options?.isDismissable}
            onDismiss={handleDismissModal}
            hideDismissBtn={false || options?.hideDismissBtn}
            size={modalSize}
        >
            {renderContent()}
        </Modal>
    )
}

export default forwardRef(AlertModal)
