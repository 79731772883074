import { DefaultSeoProps } from "next-seo"

import _c from "./constants"

// --------------------------------------------------------
// Constants
// --------------------------------------------------------

export const titleTemplate = "%s | LiveLink"

export const defaultPageTitle = "LiveLink"

export const defaultPageDescription =
    "LiveLink is a platform for creators to set up memberships, host paid livestreams, build a community and automate marketing."

export const getPageTitle = (title: string) => titleTemplate.replace("%s", title)

// --------------------------------------------------------
// Default Meta
// --------------------------------------------------------

const defaultMetaTags: DefaultSeoProps = {
    title: defaultPageTitle,
    description: defaultPageDescription,
    openGraph: {
        type: "website",
        title: defaultPageTitle,
        locale: "en_US",
        site_name:
            "LiveLink | Setup your creator membership, host paid Livestreams, build a community and automate your marketing.",
        description: defaultPageDescription,
        images: [
            {
                url: _c.OG_IMAGE_URL,
                width: 1200,
                height: 630,
            },
        ],
    },
    twitter: {
        cardType: "summary_large_image",
        site: "@livelinkvip",
        handle: "@livelinkvip",
    },
    additionalMetaTags: [
        {
            name: "author",
            content: "LiveLink",
        },
        {
            name: "google-site-verification",
            content: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION!,
        },
        {
            name: "facebook-domain-verification",
            content: process.env.NEXT_PUBLIC_FACEBOOK_SITE_VERIFICATION!,
        },
    ],
    dangerouslySetAllPagesToNoIndex: _c.DANGEROUS_GLOBAL_DO_NOT_INDEX,
    dangerouslySetAllPagesToNoFollow: _c.DANGEROUS_GLOBAL_DO_NOT_INDEX,
}

export default defaultMetaTags
