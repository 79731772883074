import { ReactNode } from "react"
import { EAnimation } from "../@types/global_types"

export interface IAlertOptions {
    title: string
    message: string | ReactNode
    primaryAction: {
        label: string
        onClick?: (e?: any) => void
    }
    icon?: JSX.Element
    secondaryAction?: {
        label: string
        onClick?: () => void
    }
    size?: "sm" | "md" | "lg" | "xl"
    onDismiss?: () => void
    isDismissable?: boolean
    hideDismissBtn?: boolean
    animation?: EAnimation
    variant?: "danger" | "dark"
    buttonDirection?: "row" | "column"
}

export default class AlertController {
    public static _showModal?: (options: IAlertOptions) => void
    public static _hideModal?: undefined | (() => void)

    public static _setControls = (showModal: any, hideModal: any) => {
        AlertController._showModal = showModal
        AlertController._hideModal = hideModal
    }

    public static show = async (options: IAlertOptions) => {
        if (!AlertController?._showModal) {
            return
        }
        AlertController?._showModal(options)
    }
}
