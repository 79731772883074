import { User } from "@prisma/client"
import { AE } from "../@types/analytics"
import _c from "../configs/constants"
import { isTrackableEnvironment } from "../utils/browser_util"

const DRIP_IDENTIFY_TIMEOUT = 5000

interface DripResponse {
    success: boolean
    visitor_uuid: string
    anonymous: boolean
    error?: string
}

export class DripFeClient {
    private static visitorId: string | undefined
    private static getClient = (): any => {
        return (window as any)?._dcq
    }
    static getVisitorId = (): string | undefined => {
        return this.visitorId
    }
    static setVisitorId = (response: DripResponse) => {
        if (!response.success) {
            return
        }
        if (response.anonymous) {
            this.visitorId = response.visitor_uuid
        } else {
            this.visitorId = undefined
        }
    }
    static trackEvent = (event: AE, payload?: Record<string, string | number | boolean>) => {
        if (!isTrackableEnvironment()) {
            return
        }
        DripFeClient.getClient()?.push([
            "track",
            event,
            {
                ...payload,
                success: (response: DripResponse) => {
                    this.setVisitorId(response)
                },
            },
        ])
    }
    static setProperties = async (properties: Record<string, any>) => {
        if (!isTrackableEnvironment()) {
            return
        }

        const attributes = { ...properties }

        Object.keys(attributes).forEach((key) => {
            if (key.includes("$")) {
                delete attributes[key]
            }
            if (attributes[key] === undefined) {
                delete attributes[key]
            }
        })
        await new Promise<void>((resolve) => {
            DripFeClient.getClient()?.push([
                "identify",
                {
                    ...attributes,
                    success: (resp: DripResponse) => {
                        this.setVisitorId(resp)
                        resolve()
                    },
                    failure: (resp: DripResponse) => {
                        if (_c.isDevOrStaging) {
                            console.error("DripFEClient.setProperties: Unable to setProperties " + resp.error)
                            console.error(resp.error)
                        }
                        resolve()
                    },
                },
            ])
            setTimeout(() => {
                if (_c.isDevOrStaging) {
                    console.error("DripFEClient.setProperties: Forcefully ended.")
                }
                resolve()
            }, DRIP_IDENTIFY_TIMEOUT)
        })
    }
    static identifyUser = async (user: Partial<User>, tags?: string[]) => {
        if (!isTrackableEnvironment()) {
            return
        }

        const attributes: Record<string, any> = {
            email: user.email,
            user_id: user.id,
            first_name: user?.firstName,
            last_name: user?.lastName,
            creator: user.isCreator,
            tags,
        }

        if (user.isCreator) {
            attributes.slug = user.slug
        }

        Object.keys(attributes).forEach((key) => {
            if (attributes[key] === undefined) {
                delete attributes[key]
            }
        })

        await new Promise<void>((resolve) => {
            DripFeClient.getClient()?.push([
                "identify",
                {
                    ...attributes,
                    success: (resp: DripResponse) => {
                        this.setVisitorId(resp)
                        resolve()
                    },
                    failure: (resp: DripResponse) => {
                        if (_c.isDevOrStaging) {
                            console.error("Unable to identifyUser for Drip")
                            console.error(resp.error)
                        }
                        resolve()
                    },
                },
            ])
            setTimeout(() => {
                if (_c.isDevOrStaging) {
                    console.error("Forcefully ended Drip.identifyUser")
                }
                resolve()
            }, DRIP_IDENTIFY_TIMEOUT)
        })
    }
}
