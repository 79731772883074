import _c from "../configs/constants"

export const getFaviconNameForEnv = () => {
    if (_c.isDev) {
        return "/favicon-dev.ico"
    }
    if (_c.isStaging) {
        return "/favicon-staging.ico"
    }
    return "/favicon.ico"
}
