import { Flex, Tab, TabList, Tabs } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import { LinkedInEvents } from "../@types/analytics"
import {
    EPlatformSubscriptionPlan,
    EPlatformSubscriptionPlanFrequency,
    EPlatformSubscriptionUpdateType,
} from "../@types/subscription_types"
import _c from "../configs/constants"
import Analytics from "../controllers/analytics_controller"
import { BasePlatformSubscriptionPlan } from "../server/dao/platform_subscription_plan_dao"
import { BaseUser } from "../server/dao/user_dao"
import { linkToPurchasePlatformSubscription } from "../utils/link_util"
import PricingCard from "./subscriptions/PricingCard"

interface IPricingPageProps {
    plans: BasePlatformSubscriptionPlan[]
    currentUser?: BaseUser & { token?: string }
}

const PricingSection = ({ plans, currentUser }: IPricingPageProps) => {
    const router = useRouter()

    const [isMonthly, setMonthly] = useState(false)

    useEffect(() => {
        if (!router.query.isCreatorSignup) {
            return
        }
        Analytics.trackLinkedInConversion(
            LinkedInEvents.LINKEDIN_BUY_PLATFORM_SUBSCRIPTION_START_EVENT_ID,
        )
    }, [])

    const handleClickPlan = async (planId: string, action?: EPlatformSubscriptionUpdateType) => {
        if (action === EPlatformSubscriptionUpdateType.CANCEL) {
            router.push("/account/subscriptions/cancel")
            return
        }
        let checkoutPath = linkToPurchasePlatformSubscription(
            planId,
            EPlatformSubscriptionPlan.Snippets,
            action,
        )
        if (!!router.query.projectId) {
            checkoutPath += `&projectId=${router.query.projectId}`
        }
        router.push(checkoutPath)
    }

    return (
        <Flex direction="column" zIndex={2}>
            <Flex width="100%" justifyContent="flex-end" pr="20px" pb={[4, null, 0]}>
                <Tabs
                    variant="lightPills"
                    onChange={() => setMonthly(!isMonthly)}
                    index={isMonthly ? 0 : 1}
                    width="unset"
                >
                    <Flex maxWidth="200px">
                        <TabList>
                            <Tab>Monthly</Tab>
                            <Tab>Yearly</Tab>
                        </TabList>
                    </Flex>
                </Tabs>
            </Flex>
            <Flex direction={["column", null, "row"]} alignItems="stretch">
                {plans
                    .filter(({ interval }) =>
                        isMonthly
                            ? interval === EPlatformSubscriptionPlanFrequency.MONTH
                            : interval === EPlatformSubscriptionPlanFrequency.YEAR,
                    )
                    .map((plan) => (
                        <Flex key={plan.id}>
                            <PricingCard
                                isMonthly={isMonthly}
                                onClickPlan={handleClickPlan}
                                plan={plan}
                                currentUser={currentUser}
                            />
                        </Flex>
                    ))}
            </Flex>
        </Flex>
    )
}

export default PricingSection
