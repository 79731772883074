import { Button, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react"
import dayjs from "dayjs"
import { useMemo } from "react"
import { BiCheckCircle, BiRightArrowAlt } from "react-icons/bi"

import {
    EPlatformSubscriptionPlanFrequency,
    EPlatformSubscriptionType,
    EPlatformSubscriptionUpdateType,
} from "../../@types/subscription_types"
import { BasePlatformSubscriptionPlan } from "../../server/dao/platform_subscription_plan_dao"
import { BaseUser } from "../../server/dao/user_dao"
import { formatPrice } from "../../utils/currency_util"

const PRICING_PLAN_LOOKUP = {
    [EPlatformSubscriptionType.MAIN_STARTER]: {
        isHighlighted: false,
        description: "For those starting out and with their business",
        benefits: [
            <>
                Up to <strong>5</strong> subscribers
            </>,
            <>
                Up to <strong>10</strong> attendees per live
            </>,
            <>
                Up to <strong>50</strong> community members
            </>,
            <>
                Up to <strong>6</strong> community channels
            </>,
            <>
                Up to <strong>100</strong> email marketing automations
            </>,
            <>Basic usage dashboard</>,
            <>CSV Export</>,
            <>5% commission on revenue + processing fees</>,
        ],
    },
    [EPlatformSubscriptionType.MAIN_PRO]: {
        isHighlighted: true,
        description: "For larger creators that want to scale up their business and offering.",
        benefits: [
            <>
                <strong>Unlimited</strong> subscribers
            </>,
            <>
                <strong>Unlimited</strong> attendees per live
            </>,
            <>
                <strong>Unlimited</strong> community members
            </>,
            <>
                <strong>Unlimited</strong> community channels
            </>,
            <>
                <strong>Unlimited</strong> email marketing automations
            </>,
            <>Advanced usage dashboard</>,
            <>CSV Export</>,
            <>0% commission (only payment processing applied)</>,
            <>Create courses</>,
        ],
    },
    [EPlatformSubscriptionType.SNIPPETS_STARTER]: {
        isHighlighted: false,
        description: "Everything you need to get started with creating AI clips.",
        benefits: [
            <>
                <strong>100</strong> video upload minutes
            </>,
            <>
                <strong>720p</strong> rendering quality
            </>,
            <>5GB storage</>,
            <>Standard templates</>,
            <>Automatic captions</>,
            <>Basic support</>,
        ],
    },
    [EPlatformSubscriptionType.SNIPPETS_PRO]: {
        isHighlighted: true,
        description: "For the more established creator who posts quality videos regularly.",
        benefits: [
            <>
                <strong>500</strong> video upload minutes
            </>,
            <>
                <strong>1080p</strong> rendering quality
            </>,
            <>
                <strong>500GB</strong> storage
            </>,
            <>Standard templates</>,
            <>Automatic captions</>,
            <>Priority expert support</>,
        ],
    },
}

interface IPricingCardProps {
    plan: NonNullable<BasePlatformSubscriptionPlan>
    onClickPlan: (planId: string, action?: EPlatformSubscriptionUpdateType) => void
    isMonthly: boolean
    currentUser?: BaseUser
}

const PricingCard = (props: IPricingCardProps) => {
    const { plan, currentUser, isMonthly, onClickPlan } = props

    const isAuthedUser = !!currentUser

    const model = PRICING_PLAN_LOOKUP[plan.name as EPlatformSubscriptionType]
    const price = formatPrice(
        {
            currency: plan.currency,
            totalInLowestDenom: isMonthly ? plan.totalInLowestDenom : plan.totalInLowestDenom / 12,
        },
        true,
    )

    const activeSubscription = useMemo(() => {
        const hasSubscription = !!currentUser && !!currentUser.platformSubscriptions.length
        if (hasSubscription) {
            const activeSubs = currentUser.platformSubscriptions.filter(
                (sub) => sub.status === "active" || sub.status === "trialing",
            )
            return activeSubs[0]
        }
        return null
    }, [currentUser])

    const isCurrentPlanSubscribed = useMemo(() => {
        if (activeSubscription) {
            return (
                plan.name === activeSubscription.plan.name &&
                plan.interval == activeSubscription.interval
            )
        }
        return false
    }, [plan, activeSubscription])

    const hasScheduledDowngrade =
        activeSubscription &&
        activeSubscription.futurePlanId &&
        activeSubscription.futurePlanId === plan.id

    const hasHighlightedFields = useMemo(
        () => Object.values(PRICING_PLAN_LOOKUP).some((p) => p.isHighlighted),
        [],
    )

    const currentPrice = formatPrice(
        {
            currency: activeSubscription?.plan.currency!,
            totalInLowestDenom: activeSubscription?.plan.totalInLowestDenom!,
        },
        true,
    )

    const actionForPlan = useMemo(() => {
        let cta = "Current Plan"
        let action

        if (activeSubscription) {
            if (isCurrentPlanSubscribed) {
                cta = "Current plan"
                action = EPlatformSubscriptionUpdateType.CANCEL
            } else if (isMonthly) {
                if (
                    activeSubscription.interval === EPlatformSubscriptionPlanFrequency.MONTH &&
                    currentPrice < price
                ) {
                    cta = "Upgrade"
                    action = EPlatformSubscriptionUpdateType.UPGRADE
                } else if (
                    (activeSubscription.interval === EPlatformSubscriptionPlanFrequency.MONTH &&
                        currentPrice > price) ||
                    activeSubscription.interval === EPlatformSubscriptionPlanFrequency.YEAR
                ) {
                    cta = hasScheduledDowngrade ? "Scheduled downgrade" : "Downgrade"
                    action = EPlatformSubscriptionUpdateType.DOWNGRADE
                }
            } else if (!isMonthly) {
                if (
                    (activeSubscription.interval === EPlatformSubscriptionPlanFrequency.YEAR &&
                        currentPrice < price) ||
                    activeSubscription.interval === EPlatformSubscriptionPlanFrequency.MONTH
                ) {
                    cta = "Upgrade"
                    action = EPlatformSubscriptionUpdateType.UPGRADE
                } else if (
                    activeSubscription.interval === EPlatformSubscriptionPlanFrequency.YEAR &&
                    currentPrice > price
                ) {
                    cta = hasScheduledDowngrade ? "Scheduled downgrade" : "Downgrade"
                    action = EPlatformSubscriptionUpdateType.DOWNGRADE
                }
            }
        } else {
            cta =
                plan.trialPeriodDays! > 0
                    ? `Try free for ${plan.trialPeriodDays} days`
                    : "Get started"
        }

        return { cta, action }
    }, [plan, activeSubscription, hasScheduledDowngrade, isCurrentPlanSubscribed, isMonthly])

    const renderButtonIcon = () => {
        if (isCurrentPlanSubscribed) {
            return null
        }
        const textColor = (() => {
            if (model.isHighlighted) {
                return "white"
            }
            if (hasHighlightedFields) {
                return "#212121"
            }
            return "white"
        })()
        if (hasScheduledDowngrade) {
            return (
                <Image
                    alt=""
                    src={
                        model.isHighlighted
                            ? "/images/time_usage_icon.svg"
                            : "/images/time_usage_icon_dark.svg"
                    }
                    width="16px"
                    height="16px"
                    marginLeft="4px"
                />
            )
        }
        return <BiRightArrowAlt size={20} color={textColor} />
    }

    const renderCtaButton = () => {
        const variant = (() => {
            if (isCurrentPlanSubscribed) {
                return "outline"
            }
            if (model.isHighlighted) {
                return "primaryGreen"
            }
            if (hasHighlightedFields) {
                return "whiteWithDarkOutline"
            }
            return "primary"
        })()
        return (
            <Button
                variant={variant}
                size="lg"
                width="full"
                onClick={() => onClickPlan(plan.id, actionForPlan.action)}
                isDisabled={!!hasScheduledDowngrade || isCurrentPlanSubscribed}
                borderWidth={2}
                // @ts-ignore
                rightIcon={renderButtonIcon()}
            >
                <Text as="span" color="currentcolor">
                    {actionForPlan.cta}
                </Text>
            </Button>
        )
    }

    const lowerTextCta = (() => {
        if (isCurrentPlanSubscribed) {
            return (
                <Button
                    variant="link"
                    colorScheme="gray"
                    onClick={() => onClickPlan(plan.id, actionForPlan.action)}
                >
                    <Text as="span" textAlign="center" color="#A99F9F">
                        Cancel Plan
                    </Text>
                </Button>
            )
        }
        if (hasScheduledDowngrade) {
            const downgradeDate = dayjs(activeSubscription.scheduledDowngrade).format(
                "Do [of] MMMM YYYY",
            )
            return (
                <Text as="span" textAlign="center" color="#A99F9F">
                    Downgrade scheduled for the {downgradeDate}
                </Text>
            )
        }
        return (
            <Text as="span" textAlign="center" color="#A99F9F">
                Terms and conditions apply
            </Text>
        )
    })()

    return (
        <Flex
            key={plan.id}
            direction="column"
            position="relative"
            maxWidth="360px"
            padding="36px 4px 4px 4px"
            borderRadius="30px"
            margin={{ base: undefined, md: "20px" }}
            backgroundColor={model?.isHighlighted ? "#06D6A0" : undefined}
        >
            {model?.isHighlighted && (
                <Text
                    position="absolute"
                    top="8px"
                    left="calc(50% - 50px)"
                    color="white"
                    fontWeight="bold"
                    fontSize="14px"
                >
                    MOST POPULAR
                </Text>
            )}
            <VStack
                flex="1"
                minHeight="200px"
                padding="30px"
                borderRadius="30px"
                backgroundColor="white"
                border="1px solid #EAEAEA"
                boxShadow="0px 12px 22px #00000029"
                textAlign="center"
            >
                <Heading
                    fontSize="18px"
                    fontWeight="bold"
                    color="#06D6A0"
                    textTransform="uppercase"
                >
                    {plan.name}
                </Heading>
                <Text>
                    <Text as="span" fontSize="32px" fontWeight="bold" paddingRight="1">
                        {price}
                    </Text>
                    {"/month"}
                </Text>
                {!isMonthly && (
                    <Text fontWeight={600} color="#B7B5B5">
                        Billed yearly
                    </Text>
                )}
                <Text>{model.description}</Text>
                {isAuthedUser && renderCtaButton()}
                <VStack spacing="0" align="stretch" width="full" flex="1" textAlign="left">
                    {model.benefits.map((benefit, i) => (
                        <Text
                            key={`${benefit.key}-${i}`}
                            borderTop="1px solid #EAEAEA"
                            paddingY={2}
                            marginBottom={0}
                        >
                            {benefit}
                        </Text>
                    ))}
                </VStack>
                {!isAuthedUser && renderCtaButton()}
                {lowerTextCta}
            </VStack>
        </Flex>
    )
}

export default PricingCard
