import _c from "../configs/constants"
import { ERequestType, IStaticRoute } from "../configs/routes"
import { isServer } from "./browser_util"
import { APIResponseError, ResponseParseError, SignatureVerificationError } from "./error_util"

/**
 * Merges headers with default headers.
 */
export const generateRequestHeaders = (headers = {}) => {
    const defaultHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        [_c.LIVELINK_API_KEY_HEADER]: _c.LIVELINK_API_KEY_VALUE,
    }
    if (_c.isDev) {
        defaultHeaders["bypass-tunnel-reminder"] = "true"
    }
    return {
        ...defaultHeaders,
        ...headers,
    }
}

/**
 * Serializes parameters for GET request
 */
export const serializeQueryParams = (params: Record<any, any>) =>
    Object.entries(params)
        .map(([key, data]) => {
            if (!data) {
                return ""
            }
            if (data.constructor === Array) {
                return data
                    .map((val) => encodeURIComponent(key) + "=" + encodeURIComponent(val))
                    .join("&")
            }
            return encodeURIComponent(key) + "=" + encodeURIComponent(data)
        })
        .filter(Boolean)
        .join("&")

/**
 * General method for handling requests for the application.
 *
 * This is not to be used directly from any view file. This is solely used
 * by files in @services.
 *
 * It takes the following steps:
 * 1. Get authentication token for request, if necessary.
 * 2. Create request headers.
 * 3. Handle all data processing including GET url serialization and POST json stringifying.
 * 4. Create request object, attach handlers and dispatch request.
 * 5. Handle request errors with custom error types (turn off with errorOptions.transform = false).
 */
const createRequestHandler =
    (token?: string | null) =>
    async (
        { url: endpoint, type: method, options }: IStaticRoute,
        data: any = null,
        errorOptions = {
            throw: true, // option to throw errors, or return response as-is
            transform: !isServer, // throw errors as-is from calls if server-side
        },
    ) => {
        // Get headers
        let headers: any
        if (!options?.external) {
            headers = generateRequestHeaders()
        } else {
            headers = {}
        }
        // Attach auth
        if (!(options?.noToken || options?.external || options?.internal)) {
            const accessToken = token
            if (accessToken) {
                headers = {
                    ...headers,
                    authorization: accessToken,
                }
            }
        }
        if (!!options?.headers) {
            if (options?.headers?.["Content-Type"] === "multipart/form-data") {
                delete headers["Content-Type"]
                delete headers["Accept"]
            } else {
                headers = {
                    ...headers,
                    ...options.headers,
                }
            }
        }
        // Parse request data
        let requestData = null
        let requestUrl = endpoint
        if ((method === ERequestType.POST || method === ERequestType.DELETE) && data !== null) {
            requestData =
                headers["Content-Type"] === "application/json" ? JSON.stringify(data) : data
        }
        if (method === ERequestType.GET && data !== null) {
            requestUrl += "?" + serializeQueryParams(data)
        }
        try {
            const response = await fetch(requestUrl, {
                method,
                headers: new Headers(headers as any),
                body: requestData,
            })

            if (response.ok) {
                if (options?.blobResponse) {
                    const blob = await response.blob()
                    return blob
                }
                if (options?.textResponse) {
                    const text = await response.text()
                    return text
                }
            }

            // Handle response
            const json = await response.json()
            if (response.status === 200 && json !== null) {
                return json
            }

            if (response.status !== 200 && !errorOptions.throw) {
                // Return response as-is without throw
                return json
            }

            if (json?.message === "Signature verification failed") {
                throw new SignatureVerificationError()
            }

            // Support backend error formats
            if (json.errors || json.error || json.message) {
                throw APIResponseError.withResponse(json, response.status, {
                    method,
                    url: endpoint,
                    payload: data,
                })
            } else {
                throw ResponseParseError.withResponse(json, response.status, {
                    method,
                    url: endpoint,
                    payload: data,
                })
            }
        } catch (e: any) {
            throw e
        }
    }

export const handleRequest = createRequestHandler()
export const handleRequestWithToken = (token?: string | null) => createRequestHandler(token)

export enum ENetworkSpeed {
    Fast = "fast",
    Slow = "slow",
    Unknown = "unknown",
}

export const getNetworkSpeed = () => {
    if (!("connection" in window.navigator)) {
        return ENetworkSpeed.Unknown
    }
    // @ts-ignore - connection is not in the types
    const networkInfo = window.navigator.connection
    const speedType: string = networkInfo.effectiveType
    if (speedType) {
        if (speedType === "4g" || speedType === "5g") {
            return ENetworkSpeed.Fast
        }
        if (speedType.includes("2g") || speedType === "3g") {
            return ENetworkSpeed.Slow
        }
    }
    const speed = networkInfo.downlink
    if (speed) {
        if (speed >= 5) {
            return ENetworkSpeed.Fast
        } else {
            return ENetworkSpeed.Slow
        }
    }
    return ENetworkSpeed.Unknown
}
