import { GetCourseResponse } from "../pages/api/v1/course"
import routes from "../configs/routes"
import { handleRequest } from "../utils/network_util"
import { PostCreateCourseBody, PostCreateCourseResponse } from "../pages/api/v1/course/create"
import { CreateCourseCheckoutSessionBody, CreateCourseCheckoutSessionResponse } from "../pages/api/v1/course/checkout"
import { GetCoursePurchaseQuery, GetCoursePurchaseResponse } from "../pages/api/v1/course/purchase"
import { PostUpdateCourseBody, PostUpdateCourseResponse } from "../pages/api/v1/course/update"

const courseService = (hr = handleRequest) => ({
    getCourseBySlug: (slug: string): Promise<GetCourseResponse> => hr(routes.GET_COURSE, { slug }),
    getCourseById: (id: string): Promise<GetCourseResponse> => hr(routes.GET_COURSE, { id }),
    postCreateCourse: (query: PostCreateCourseBody): Promise<PostCreateCourseResponse> =>
        hr(routes.POST_CREATE_COURSE, query),
    postUpdateCourse: (query: PostUpdateCourseBody): Promise<PostUpdateCourseResponse> =>
        hr(routes.POST_UPDATE_COURSE, query),
    createCourseCheckoutSession: (
        body: CreateCourseCheckoutSessionBody,
    ): Promise<CreateCourseCheckoutSessionResponse> =>
        hr(routes.CREATE_COURSE_CHECKOUT_SESSION, body),
    getCoursePurchase: (body: GetCoursePurchaseQuery): Promise<GetCoursePurchaseResponse> => hr(routes.GET_COURSE_PURCHASE, body),
})

export default courseService
