import { ECurrency } from "../@types/payment_types"

export const printMoney = ({
    total,
    options = {},
}: {
    total: number
    options?: {
        currency?: string
        roundUp?: boolean
    }
}): string => {
    const { currency, roundUp = false } = options
    switch (currency?.toUpperCase()) {
        case "USD":
            return formatToDollars(total, roundUp)
        case "GBP":
        default:
            return formatToPounds(total, roundUp)
    }
}

export const formatMoney = (amount: number, roundUp = false) =>
    amount
        .toFixed(roundUp ? 0 : 2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        .replace(".00", "")

export const formatToDollars = (amount: number, roundUp?: boolean) =>
    `$${formatMoney(amount, roundUp)}`

export const formatToPounds = (amount: number, roundUp?: boolean) =>
    `£${formatMoney(amount, roundUp)}`

type Currency = {
    symbol: "£" | "$"
    value: ECurrency
}

export const supportedCurrencies: Record<string, Currency> = {
    usd: {
        symbol: "$",
        value: ECurrency.USD,
    },
    gbp: {
        symbol: "£",
        value: ECurrency.GBP,
    },
}

export const formatPrice = (
    price: { currency: string; totalInLowestDenom: number },
    roundUp = false,
) => {
    return printMoney({
        total: price.totalInLowestDenom > 0 ? price.totalInLowestDenom / 100 : 0,
        options: { currency: price.currency, roundUp },
    })
}
