import { AppEvent, PostSchedule, PostScheduleTask } from "@prisma/client"
import findKey from "lodash/findKey"

import { ProviderRecordResult } from "../server/controllers/provider_controller"
import { EPostScheduleTaskStatus } from "../server/dao/post_schedule_task_dao"
import { BaseSnippetTask } from "../server/dao/snippet_task_dao"
import { BaseSnippetVideo } from "../server/dao/snippet_video_dao"
import { BaseUser } from "../server/dao/user_dao"
import { IPostScheduleModalContentFormFieldsPayload } from "../utils/social_util"

export enum EActiveSocialPlatform {
    Instagram = "instagram",
    TikTok = "tiktok",
}

export enum EPlaceholderSocialPlatform {
    YoutubeShorts = "youtube-shorts",
    LinkedIn = "linkedin",
}

export enum EActiveSocialPlatformStatus {
    Processing = "0",
    Connected = "1",
    Disconnected = "2",
    Scheduled = "3",
    ComingSoon = "4",
    Loading = "5",
    Idle = "6",
    Error = "7",
    Cancelled = "8",
    ReAuth = "9",
    RateLimited = "10",
}

export enum EPostScheduleSnippetVideoStatus {
    NotChanged = "not-changed",
    Scheduled = "scheduled",
    Processing = "in-progress",
    Completed = "completed",
    Cancelled = "cancelled",
    Failed = "failed",
    NeedReAuth = "need-reauth",
    Skipped = "skipped",
    RateLimited = "rate-limited",
    IssuePosting = "issueposting",
}

export enum EDisclosureAllowedAction {
    Comment = "comment",
    Duet = "duet",
    Stitch = "stitch",
}

export enum ETikTokPrivacyLevel {
    PublicToEveryone = "PUBLIC_TO_EVERYONE",
    FollowerOfCreator = "FOLLOWER_OF_CREATOR",
    MutualFollowFriends = "MUTUAL_FOLLOW_FRIENDS",
    SelfOnly = "SELF_ONLY",
}

export enum EInstagramPrivacyLevel {
    PUBLIC = "PUBLIC",
}

export const EPostSchedulePlatformStatusMapping: Record<
    EPostScheduleSnippetVideoStatus,
    EActiveSocialPlatformStatus
> = {
    [EPostScheduleSnippetVideoStatus.Failed]: EActiveSocialPlatformStatus.Error,
    [EPostScheduleSnippetVideoStatus.RateLimited]: EActiveSocialPlatformStatus.RateLimited,
    [EPostScheduleSnippetVideoStatus.IssuePosting]: EActiveSocialPlatformStatus.Error,
    [EPostScheduleSnippetVideoStatus.NotChanged]: EActiveSocialPlatformStatus.Idle,
    [EPostScheduleSnippetVideoStatus.Completed]: EActiveSocialPlatformStatus.Idle,
    [EPostScheduleSnippetVideoStatus.Processing]: EActiveSocialPlatformStatus.Processing,
    [EPostScheduleSnippetVideoStatus.Scheduled]: EActiveSocialPlatformStatus.Scheduled,
    [EPostScheduleSnippetVideoStatus.Cancelled]: EActiveSocialPlatformStatus.Cancelled,
    [EPostScheduleSnippetVideoStatus.NeedReAuth]: EActiveSocialPlatformStatus.ReAuth,
    [EPostScheduleSnippetVideoStatus.Skipped]: EActiveSocialPlatformStatus.Idle,
}

export enum ESocialPlatformErrorType {
    OAuthException = "OAuthException",
    TikTokInitializeUploadError = "TikTokInitializeUploadError",
    TikTokInitializeUploadRateLimitError = "TikTokInitializeUploadRateLimitError",
    SocialAccountAlreadyLinkedError = "SocialAccountAlreadyLinkedError",
    ExpiredAccessTokenError = "ExpiredAccessTokenError",
    InstagramPublishLimitError = "InstagramPublishLimitError",
    InstagramMediaCreationError = "InstagramMediaCreationError",
    InsufficientPermissionsError = "InsufficientPermissionsError",
    MandaroryQueryFieldsLoginError = "MandaroryQueryFieldsLoginError",
    InvalidCodeOrStateError = "InvalidCodeOrStateError",
    InstagramProfileNotFetchedError = "InstagramProfileNotFetchedError",
    InstagramNotFoundForUserError = "InstagramNotFoundForUserError",
    InstagramNotFoundPageError = "InstagramNotFoundPageError",
}

export enum ESocialPlatformErrorText {
    OAuthException = "Authorization Error",
    SocialAccountAlreadyLinkedError = "The platform account is already associated with another user",
    TikTokInitializeUploadError = "Uploading video to TikTok failed",
    TikTokInitializeUploadRateLimitError = "Rate limit reached for TikTok. Please try again later",
    ExpiredAccessTokenError = "Expired access token",
    InstagramPublishLimitError = "Publish limit reached for Instagram",
    InstagramMediaCreationError = "Instagram media creation error",
    InsufficientPermissionsError = "Insufficient permissions",
    MandaroryQueryFieldsLoginError = "Mandarory query fields are missing",
    InvalidCodeOrStateError = "Invalid code or state",
    InstagramProfileNotFetchedError = "Instagram profile not fetched",
    InstagramNotFoundForUserError = "Instagram not found for user",
    InstagramNotFoundPageError = "Instagram not found page",
}

interface ISocialPlatformOAuthBaseParams {
    userId: string
    snippetTaskId: string
    snippetId: string
}

interface ISocialPlatformOAuthSuccessParams extends ISocialPlatformOAuthBaseParams {
    valid: true
    code: string
}

interface ISocialPlatformOAuthErrorParams extends ISocialPlatformOAuthBaseParams {
    valid: false
    errorType: keyof typeof SocialPlatformErrorTypeMapping
}

export type TSocialPlatformOAuthParams =
    | ISocialPlatformOAuthSuccessParams
    | ISocialPlatformOAuthErrorParams

export interface ISocialAccountUserInfo {
    id: string
    name: string
    profilePictureUrl: string
    category: string
}

export enum ESocialPlatformTaskSlug {
    PrepareScheduling = "PrepareScheduling",
    SchedulePosting = "SchedulePosting",
    PreparePosting = "PreparePosting",
    FinalizePosting = "FinalizePosting",
}

export interface IPostScheduleTaskModel<T extends ESocialPlatformTaskSlug> {
    run: (args: IPostScheduleTaskArgs[T]) => Promise<IPostScheduleTaskReturn[T]>
}

export interface IPostScheduleTaskProperties {
    userId: BaseUser["id"]
    form: IPostScheduleModalContentFormFieldsPayload
    taskId: BaseSnippetTask["id"]
    snippetId: BaseSnippetVideo["id"]
    active: boolean
    platform: EActiveSocialPlatform
}

export interface IPostingTaskProperties extends Omit<IPostScheduleTaskProperties, "providers"> {
    providers: ProviderRecordResult
}

export interface IPostScheduleTaskArgs {
    [ESocialPlatformTaskSlug.PrepareScheduling]: {
        eventId: AppEvent["id"]
    } & IPostScheduleTaskProperties
    [ESocialPlatformTaskSlug.SchedulePosting]: {
        eventId: AppEvent["id"]
        platform: EActiveSocialPlatform
    }
    [ESocialPlatformTaskSlug.PreparePosting]: {
        taskId: PostScheduleTask["id"]
    }
    [ESocialPlatformTaskSlug.FinalizePosting]: {
        taskId: PostScheduleTask["id"]
        status: EPostScheduleTaskStatus
    }
}

export interface IPostScheduleTaskReturn {
    [ESocialPlatformTaskSlug.PrepareScheduling]: PostScheduleTask | undefined
    [ESocialPlatformTaskSlug.SchedulePosting]: PostScheduleTask
    [ESocialPlatformTaskSlug.PreparePosting]: PostSchedule[]
    [ESocialPlatformTaskSlug.FinalizePosting]: PostScheduleTask
}

export const SocialPlatformErrorTypeMapping = {
    [ESocialPlatformErrorType.OAuthException]: 1,
    [ESocialPlatformErrorType.SocialAccountAlreadyLinkedError]: 2,
    [ESocialPlatformErrorType.InstagramPublishLimitError]: 3,
    [ESocialPlatformErrorType.InvalidCodeOrStateError]: 4,
} as const

export const getSocialPlatformErrorCodeByType = (
    type: keyof typeof SocialPlatformErrorTypeMapping,
) => {
    return SocialPlatformErrorTypeMapping[type]
}

export const getSocialPlatformErrorByType = (code: number) => {
    return findKey(SocialPlatformErrorTypeMapping, (value) => value === code) as
        | ESocialPlatformErrorType
        | undefined
}
