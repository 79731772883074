import { Flex, Spinner } from "@chakra-ui/react"

const SpinnerLoader = () => (
    <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        width="100vw"
        height="100vh"
    >
        <Spinner mt="-49vh" size="xl" color="#bfc9c9" thickness="6px"/>
    </Flex>
)

export default SpinnerLoader
