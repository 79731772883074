import { Box, Container, Flex, HStack, Icon, Image, Link, Text, VStack } from "@chakra-ui/react"
import dayjs from "dayjs"
import NextLink from "next/link"
import { useRouter } from "next/router"
import { FaInstagram, FaTiktok } from "react-icons/fa"

import _c from "../configs/constants"
import SiteLink from "./SiteLink"

// TODO: make footer opt-out rather than opt-in
const ALLOWED_PAGES = [
    "/",
    "/live/create",
    "/terms",
    "/privacy",
    "/cookies",
    "/live/create",
    "/creators",
]

const BLOG_LINK =
    "https://livelink.notion.site/fd6b1fb6362e42939e30073569934ed0?v=d6948dbe57e04641a2832243d3a3c3f0"

const Footer = () => {
    const router = useRouter()
    const showFooter =
        ALLOWED_PAGES.includes(router.asPath.split("?")[0]) ||
        router.pathname === "/[creatorSlug]" ||
        router.pathname === "/live/[slug]/edit"

    if (!showFooter) {
        return null
    }

    return (
        <Container as="footer" maxW="100vw" borderTop="1px solid" borderTopColor="blackAlpha.200">
            <Container maxW="container.xl" px={[4, 6, 8]}>
                <Flex direction="column" py={[4, null, 12]}>
                    <Flex direction={["column", null, "row"]} mb={12}>
                        <VStack spacing={4} alignItems="flex-start" marginBottom={4}>
                            <NextLink href="/" passHref legacyBehavior>
                                <Box as="a">
                                    <Image
                                        src="/images/livelink_logo_text.svg"
                                        alt="LiveLink Logo"
                                        width="90px"
                                        height="auto"
                                        objectFit="contain"
                                        mx={2}
                                        my={2}
                                    />
                                </Box>
                            </NextLink>
                            <Link
                                href="https://www.tiktok.com/@livelinkvip"
                                target="_blank"
                                display="inline-flex"
                                alignItems="center"
                                pl={2}
                            >
                                <Icon as={FaTiktok} marginRight={2} /> Tiktok
                            </Link>
                            <Link
                                href="https://www.instagram.com/livelinkvip"
                                target="_blank"
                                display="inline-flex"
                                alignItems="center"
                                pl={2}
                            >
                                <Icon as={FaInstagram} marginRight={2} /> Instagram
                            </Link>
                        </VStack>
                        <Flex
                            direction={["column", null, "row"]}
                            justifyContent={["flex-start", null, "flex-end"]}
                            flex={1}
                        >
                            <VStack
                                spacing={4}
                                alignItems="flex-start"
                                direction="column"
                                pl={[8, null, 6]}
                                marginRight={[0, 0, 12]}
                                marginBottom={[4, null, 0]}
                            >
                                <Link href={BLOG_LINK} target="_black">
                                    Blog
                                </Link>
                                <SiteLink href="/signup">Become a creator</SiteLink>
                            </VStack>
                            <VStack
                                spacing={4}
                                alignItems="flex-start"
                                direction="column"
                                pl={[8, null, 6]}
                            >
                                <SiteLink href="/privacy">Privacy policy</SiteLink>
                                <SiteLink href="/cookies">Cookie policy</SiteLink>
                                <SiteLink href="/terms">Terms and conditions</SiteLink>
                            </VStack>
                        </Flex>
                    </Flex>
                    <Flex direction="row" justifyContent="space-between">
                        <Text fontSize="xs" color="blackAlpha.500" px={2} py={2}>
                            {`Copyright ${dayjs().format("YYYY")}. All rights reserved.`}
                            <br />
                            {"LiveLink is a property of Scoodle Ltd."}
                        </Text>
                        {_c.isAWS && (
                            <Text fontSize="xs" color="blackAlpha.500" px={2} py={2}>
                                {`AWS`}
                                {_c.VERSION ? ` · ${_c.VERSION}` : ""}
                                <br />
                                {_c.AWS_ENV}
                            </Text>
                        )}
                    </Flex>
                </Flex>
            </Container>
        </Container>
    )
}
export default Footer
