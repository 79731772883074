import { Course } from "@prisma/client"

import { ECourseAccessType } from "../@types/course_types"
import { EContentAccessType } from "../@types/global_types"

export const getCourseAccessRequirements = (course: Course) => {
    const accessRequirements = []
    if (course.accessType === ECourseAccessType.SUBSCRIBERS) {
        accessRequirements.push(EContentAccessType.SUBSCRIBED)
        if (!!course.subscriberCostId || !!course.secondSubscriberCostId) {
            accessRequirements.push(EContentAccessType.PURCHASED)
        }
    } else {
        if (
            !!course.guestCostId ||
            !!course.secondGuestCostId ||
            !!course.subscriberCostId ||
            !!course.secondSubscriberCostId
        ) {
            accessRequirements.push(EContentAccessType.PURCHASED)
        }
    }
    return accessRequirements
}

export const getCoursePricingTiers = (
    course: Course,
): { text: string; value: "first" | "second" }[] => {
    let tiers: { text: string; value: "first" | "second" }[] = []

    const isTieredWithAccessToAll =
        course.accessType === ECourseAccessType.ALL &&
        !!course.guestCostId &&
        !!course.secondGuestCostId

    const isTieredWithSubAccessOnly =
        course.accessType === ECourseAccessType.SUBSCRIBERS &&
        !!course.subscriberCostId &&
        !!course.secondSubscriberCostId

    if (isTieredWithAccessToAll || isTieredWithSubAccessOnly) {
        tiers = [
            { text: course.firstTierName ?? "Tier 1", value: "first" },
            {
                text: course.secondTierName ?? "Tier 2",
                value: "second",
            },
        ]
    }
    return tiers
}

export const getCourseTiersCount = (course: Course) => getCoursePricingTiers(course).length
