import { Box, Container, Image } from "@chakra-ui/react"
import NextLink from "next/link"
import React, { ReactNode } from "react"

const ErrorPageTemplate = (props: { children: ReactNode }) => (
    <Container
        width="100vw"
        height="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
    >
        <NextLink href="/" passHref legacyBehavior>
            <Box as="a" padding={6}>
                <Image src="/images/livelink_logo.svg" alt="LiveLink Logo" width="28px" />
            </Box>
        </NextLink>
        {props.children}
    </Container>
)

export default ErrorPageTemplate