import routes from "../configs/routes"
import { GetSnippetTasksForUserResponse } from "../pages/api/v1/snippet/all"
import type {
    PostDownloadSnipperTranscriptBody,
    PostSnipperDownloadTranscriptResponse,
} from "../pages/api/v1/snippet/download-transcript"
import {
    PostExportSnippetVideoBody,
    PostExportSnippetVideoResponse,
} from "../pages/api/v1/snippet/export"
import {
    TPostSnippetInitialiseBody,
    TPostSnippetInitialiseResponse,
} from "../pages/api/v1/snippet/initialise"
import {
    PostSnippetPreviewDataQuery,
    PostSnippetPreviewDataResponse,
} from "../pages/api/v1/snippet/preview"
import {
    TPostProcessUploadSnippetTaskBody,
    TPostUploadSnippetTaskResponse,
} from "../pages/api/v1/snippet/process/upload"
import {
    PostProcessYoutubeTaskBody,
    TPostProcessYoutubeTaskResponse,
} from "../pages/api/v1/snippet/process/youtube"
import { GetSnippetTaskBySnippetIdResponse } from "../pages/api/v1/snippet/snippets/[snippetId]"
import {
    PostUpdateSnippetQuery,
    PostUpdateSnippetResponse,
} from "../pages/api/v1/snippet/snippets/update"
import {
    PostStartSnippetTaskBody,
    PostStartSnippetTaskResponse,
} from "../pages/api/v1/snippet/start"
import {
    PostUpdateSnippetTaskBody,
    PostUpdateSnippetTaskResponse,
} from "../pages/api/v1/snippet/update"
import {
    PostValidateYoutubeVideoBody,
    PostValidateYoutubeVideoResponse,
} from "../pages/api/v1/snippet/validate/youtube"
import { GetSnippetVideoByIdResponse } from "../pages/api/v1/snippet/video/[videoId]"
import { handleRequest } from "../utils/network_util"
import { GetSnippetTaskByIdResponse } from "./../pages/api/v1/snippet/[snippetTaskId]"
import {
    PostUpdateManySnippetsQuery,
    PostUpdateManySnippetsResponse,
} from "./../pages/api/v1/snippet/snippets/update-many"

const snippetService = (hr = handleRequest) => ({
    getSnippetTaskById: (snippetTaskId: string): Promise<GetSnippetTaskByIdResponse> =>
        hr(routes.GET_SNIPPET_TASK_BY_ID(snippetTaskId)),

    getSnippetTaskBySnippetId: (snippetId: string): Promise<GetSnippetTaskBySnippetIdResponse> =>
        hr(routes.GET_SNIPPET_TASK_BY_SNIPPET_ID(snippetId)),

    getSnippetTasksForUser: (): Promise<GetSnippetTasksForUserResponse> =>
        hr(routes.GET_SNIPPET_TASKS),

    getSnippetVideo: (videoId: string): Promise<GetSnippetVideoByIdResponse> =>
        hr(routes.GET_SNIPPET_VIDEO(videoId)),

    postProcessYoutubeSnippetTask: (
        body: PostProcessYoutubeTaskBody,
    ): Promise<TPostProcessYoutubeTaskResponse> => hr(routes.POST_PROCESS_SNIPPETS_YOUTUBE, body),

    postProcessUploadSnippetTask: (
        body: TPostProcessUploadSnippetTaskBody,
    ): Promise<TPostUploadSnippetTaskResponse> => hr(routes.POST_PROCESS_SNIPPETS_UPLOAD, body),

    updateSnippetTask: (body: PostUpdateSnippetTaskBody): Promise<PostUpdateSnippetTaskResponse> =>
        hr(routes.POST_UPDATE_SNIPPETS, body),

    postStartSnippetTask: (body: PostStartSnippetTaskBody): Promise<PostStartSnippetTaskResponse> =>
        hr(routes.POST_START_SNIPPET_TASK, body),

    postInitializeSnippetTask: (
        body: TPostSnippetInitialiseBody,
    ): Promise<TPostSnippetInitialiseResponse> => hr(routes.POST_INITIALISE_SNIPPET_TASK, body),
    postSnippetValidateYoutubeVideo: (
        body: PostValidateYoutubeVideoBody,
    ): Promise<PostValidateYoutubeVideoResponse> =>
        hr(routes.POST_SNIPPET_VALIDATE_YOUTUBE_VIDEO, body),

    postUpdateSnippet: (body: PostUpdateSnippetQuery): Promise<PostUpdateSnippetResponse> =>
        hr(routes.POST_UPDATE_SNIPPET_VIDEO, body),

    postUpdateMultipleSnippets: (
        body: PostUpdateManySnippetsQuery,
    ): Promise<PostUpdateManySnippetsResponse> => hr(routes.POST_UPDATE_MANY_SNIPPET_VIDEOS, body),

    getSnippetTaskPreviewData: (
        body: PostSnippetPreviewDataQuery,
    ): Promise<PostSnippetPreviewDataResponse> => hr(routes.POST_GET_SNIPPET_PREVIEW, body),

    postExportSnippetVideo: (
        body: PostExportSnippetVideoBody,
    ): Promise<PostExportSnippetVideoResponse> => hr(routes.POST_EXPORT_SNIPPET_VIDEO, body),

    postDownloadTranscript: (
        body: PostDownloadSnipperTranscriptBody,
    ): Promise<PostSnipperDownloadTranscriptResponse> =>
        hr(routes.POST_SNIPPET_DOWNLOAD_TRANSCRIPT, body),
})

export default snippetService
