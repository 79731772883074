import { extendTheme } from "@chakra-ui/react"
import { createContext } from "react"

export enum EThemeMode {
    Light = "light",
    Dark = "dark",
}

export const ThemeContext = createContext<EThemeMode>(EThemeMode.Light)

const themeExtension = {
    fonts: {
        heading: "Inter, sans-serif",
        body: "Inter, sans-serif",
    },
    styles: {
        global: (props: any) => ({
            "p, span, h1, h2, h3, h4, h5, h6": {
                color: "#242424",
            },
            "html, body": {
                fontSize: "14px",
            },
            body: {
                padding: 0,
                backgroundColor: "#F7F7F7",
            },
            // TODO: We shouldn't just remove this for accessibility so needs a better solution
            ":focus": {
                outline: "0 !important",
                boxShadow: "0 0 0 0 rgba(0, 0, 0, 0) !important",
            },
        }),
    },
    shadows: {
        outline: "0 0 0 0 #f6f8fa00",
    },
    colors: {
        green: {
            "50": "#EDF8EF",
            "100": "#CCEBD2",
            "200": "#ABDEB5",
            "300": "#8AD198",
            "400": "#69C47A",
            "500": "#06D6A0",
            "600": "#3A924B",
            "700": "#2B6E38",
            "800": "#1D4925",
            "900": "#0E2513",
        },
    },
    components: {
        Checkbox: {
            variants: {
                livelink: {
                    control: {
                        padding: 1,
                        backgroundColor: "none",
                        borderColor: "black",
                        _checked: {
                            borderColor: "black",
                            background: "none",
                            _hover: {
                                borderColor: "black",
                                background: "none",
                            },
                        },
                        _hover: {
                            background: "none",
                        },
                    },
                    icon: {
                        color: "black",
                    },
                },
            },
        },
        Progress: {
            variants: {
                "project-options": {
                    track: {
                        backgroundColor: "gray",
                        borderRadius: "2px",
                    },
                    filledTrack: {
                        backgroundColor: "#06D6A0",
                        borderRadius: "2px",
                    },
                },
                livelink: {
                    track: {
                        backgroundColor: "#DFDEDD",
                        borderRadius: "2px",
                    },
                    filledTrack: {
                        backgroundColor: "black",
                        borderRadius: "2px",
                    },
                },
                loading: {
                    filledTrack: {
                        backgroundColor: "transparent",
                        backgroundImage:
                            "linear-gradient(to right,transparent 0%,#cccccc 50%,transparent 100%)",
                    },
                },
            },
        },
        Tabs: {
            variants: {
                headings: {
                    root: {
                        width: "100%",
                    },
                    tablist: {
                        borderBottomStyle: "solid",
                        borderBottomWidth: "3px",
                    },
                    tab: {
                        borderBottomStyle: "solid",
                        borderBottomWidth: "3px",
                        paddingLeft: 2,
                        paddingRight: 4,
                        fontWeight: "semibold",
                        fontSize: "20px",
                        color: "blackAlpha.500",
                        cursor: "pointer",
                        marginBottom: "-3px",
                        borderColor: "#EAEAEA",
                        _selected: {
                            color: "black",
                            borderColor: "black",
                        },
                    },
                    tabpanel: {
                        px: 0,
                    },
                    tabpanels: {
                        pt: 4,
                    },
                },
                softPills: {
                    root: {
                        width: "100%",
                    },
                    tab: {
                        cursor: "pointer",
                        paddingX: 5,
                        paddingY: 1,
                        marginRight: 3,
                        borderRadius: "9px",
                        textTransform: "uppercase",
                        fontWeight: "semibold",
                        fontSize: "15px",
                        color: "#929292",
                        backgroundColor: "transparent",
                        _selected: {
                            color: "#141414",
                            backgroundColor: "#DDDDDD",
                        },
                    },
                    tabpanel: {
                        px: 0,
                    },
                    tabpanels: {
                        pt: 4,
                    },
                },
                darkPills: {
                    root: {
                        width: "100%",
                    },
                    tablist: {
                        width: "100%",
                        backgroundColor: "#DDDDDD",
                        borderRadius: "10px",
                        border: "none",
                        padding: "7px",
                    },
                    tab: {
                        flex: 1,
                        paddingY: "13px",
                        paddingX: "35px",
                        fontWeight: "semibold",
                        fontSize: "14px",
                        borderRadius: 7,
                        color: "#000",
                        _selected: {
                            color: "#fff",
                            backgroundColor: "black",
                        },
                    },
                    tabpanel: {
                        padding: 0,
                    },
                },
                lightPills: {
                    root: {
                        width: "100%",
                    },
                    tablist: {
                        width: "100%",
                        backgroundColor: "#DDD",
                        borderRadius: "10px",
                        border: "none",
                        padding: "7px",
                    },
                    tab: {
                        flex: 1,
                        paddingY: "13px",
                        paddingX: "20px",
                        fontWeight: "semibold",
                        fontSize: "14px",
                        borderRadius: 7,
                        color: "#444",
                        _selected: {
                            color: "#000",
                            backgroundColor: "#FFF",
                        },
                    },
                    tabpanel: {
                        padding: 0,
                    },
                },
            },
        },
        Link: {
            variants: {
                navigationOption: {
                    backgroundColor: "transparent",
                    color: "#ffffff",
                    padding: "30px 15px",
                    boxSizing: "border-box",
                    fontWeight: ["semibold", "medium"],
                    _hover: {
                        textDecoration: "none",
                    },
                },
            },
        },
        Button: {
            baseStyle: {
                borderRadius: 10,
                _disabled: {
                    _hover: {
                        opacity: 1,
                    },
                },
                _loading: {
                    _hover: {
                        opacity: 1,
                    },
                },
            },
            sizes: {
                sm: {
                    borderRadius: 15,
                    paddingX: 5,
                },
                md: {
                    borderRadius: 7,
                },
                lg: {
                    height: "46px",
                },
            },
            variants: {
                primary: {
                    backgroundColor: "black",
                    color: "#ffffff",
                    // boxShadow: "0 0 2px 2px #efdfde",
                    _disabled: {
                        opacity: 1,
                        backgroundColor: "#CACACA !important",
                        _hover: {
                            // On ::hover chakra-ui applies `background: initial`
                            // which must be overrided.
                            backgroundColor: "#CACACA !important",
                        },
                    },
                    _hover: {
                        backgroundColor: "#3f3f3f",
                    },
                },
                primaryGreen: {
                    transition: "background-color 0.2s ease-in-out",
                    backgroundColor: "#05BC8B",
                    borderColor: "transparent",
                    color: "#ffffff",
                    _disabled: {
                        opacity: 1,
                        backgroundColor: "#88C2AB !important",
                        _hover: {
                            // On ::hover chakra-ui applies `background: initial`
                            // which must be overrided.
                            backgroundColor: "#88C2AB !important",
                        },
                    },
                    _hover: {
                        opacity: 1,
                        backgroundColor: "#00AB7E",
                    },
                },
                clearWithOutline: {
                    color: "#000000",
                    backgroundColor: "transparent",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    _disabled: {
                        cursor: "not-allowed",
                        opacity: 1,
                        backgroundColor: "#white !important",
                        _hover: {
                            // On ::hover chakra-ui applies `background: initial`
                            // which must be overrided.
                            backgroundColor: "#white !important",
                        },
                    },
                    _hover: {
                        backgroundColor: "#F7F7F7",
                    },
                },
                clear: {
                    color: "#000000",
                    backgroundColor: "transparent",
                    display: "flex",
                    padding: "5px",
                    width: "fit-content",
                    _hover: {
                        backgroundColor: "#f1efef",
                    },
                },
                white: {
                    backgroundColor: "#ffffff",
                    borderColor: "transparent",
                    color: "#212121",
                    _hover: {
                        backgroundColor: "#F7F7F7",
                    },
                },
                whiteWithDarkOutline: {
                    backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderColor: "#000000",
                    color: "#212121",
                    _hover: {
                        backgroundColor: "#F7F7F7",
                    },
                },
                whiteWithLightOutline: {
                    backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderColor: "#EAEAEA",
                    color: "#212121",
                    _hover: {
                        ":not(:disabled)": {
                            // override chakra style
                            background: "unset",
                            backgroundColor: "#F7F7F7",
                        },
                    },
                },
                redWithRedOutline: {
                    backgroundColor: "#ffb7b7",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderColor: "#BD2B2B",
                    color: "#AA0000",
                    _hover: {
                        backgroundColor: "#ec8181",
                    },
                },
                muted: {
                    backgroundColor: "#EAEAEA",
                    color: "black",
                    _hover: {
                        backgroundColor: "#DDDDDD",
                    },
                },
                pill: {
                    background: "rgba(6, 214, 160, 0.18);",
                    border: "2px solid #06D6A0",
                    borderRadius: "20px",
                    display: "flex",
                    height: "unset",
                    whiteSpace: "normal",
                    textAlign: "left",
                    padding: "7px",
                    _hover: { textDecoration: "none" },
                },
            },
        },
        Badge: {
            variants: {
                creatorOffering: {
                    backgroundColor: "#62D3A3",
                    color: "black",
                    borderRadius: "14px",
                    padding: "4px 14px",
                    fontSize: "13px",
                    fontWeight: 600,
                    textTransform: "none",
                },
                scheduledPost: {
                    backgroundColor: "#9A03F6",
                    color: "#FFFFFF",
                    fontWeight: 500,
                    letterSpacing: "0.04px",
                    padding: "2px 8px",
                    borderRadius: "3px",
                },
                issuedScheduledPost: {
                    backgroundColor: "#FFD84D",
                    color: "#333333",
                    fontWeight: 500,
                    letterSpacing: "0.04px",
                    padding: "2px 8px",
                    borderRadius: "3px",
                },
                scheduledError: {
                    backgroundColor: "#B02D2D",
                    color: "#FFFFFF",
                    fontWeight: 500,
                    letterSpacing: "0.04px",
                    padding: "2px 8px",
                    borderRadius: "3px",
                },
                schedulePosted: {
                    backgroundColor: "#06D6A0",
                    color: "#1E1E1E",
                    fontWeight: "600",
                    letterSpacing: "0.4px",
                    borderRadius: "3px",
                },
            },
        },
        Alert: {
            baseStyle: {
                container: {
                    borderRadius: "lg",
                },
            },
        },
        Tooltip: {
            baseStyle: {
                borderRadius: "md",
                padding: "6px 12px",
            }
        }
    },
    semanticTokens: {
        colors: {
            brandBackground: "#FDEFE6",
        },
    },
}

export type TProgressBarVariants = keyof typeof themeExtension.components.Progress.variants

export const liveLinkTheme = extendTheme(themeExtension)

export default liveLinkTheme
