import { AppProps } from "next/app"
import { useContext, useEffect, useRef } from "react"

import { BaseUser } from "../server/dao/user_dao"
import { PlatformSubscriptionModalContext } from "../utils/context_util"
import AlertModal, { IAlertModalControllerRef } from "./AlertModal"
import PlatformSubscriptionModal from "./subscriptions/PlatformSubscriptionModal"

const GlobalModals = (
    props: AppProps & {
        currentUser: BaseUser | undefined
    },
) => {
    const platformSubscriptionModalRef = useContext(PlatformSubscriptionModalContext)
    const alertModalControllerRef = useRef<IAlertModalControllerRef>({
        initialize: null,
    })

    useEffect(() => {
        alertModalControllerRef.current.initialize?.()
    }, [])

    return (
        <>
            <AlertModal ref={alertModalControllerRef} />
            <PlatformSubscriptionModal
                modalRef={platformSubscriptionModalRef}
                currentUser={props.currentUser}
            />
        </>
    )
}

export default GlobalModals
