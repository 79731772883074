export const sumNumbers = (list: number[]) => list.reduce((prev, curr) => prev + curr, 0)

export const averageNumberByKey = <T extends Record<string, any>[]>(
    listOfObjects: T,
    key: keyof T[number],
) => {
    // @ts-ignore
    return listOfObjects.reduce((prev, curr) => prev + curr[key], 0) / listOfObjects.length
}

export const sumFields = <T extends string>(
    list: Record<T, number | null | undefined>[],
    field: T,
) => {
    const validItems = list.filter((f) => !!f[field]) as Record<T, number>[]
    return sumNumbers(validItems.map((item) => item[field]))
}

export const compactNotation = (value: number) => {
    const formatter = Intl.NumberFormat("en", { notation: "compact" })
    return formatter.format(value)
}

export const isNumber = (value: any) => {
    try {
        // @ts-ignore
        return !isNaN(value)
    } catch (error) {
        return false
    }
}

export const formatBytesToGB = (bytes: bigint) => {
    // 1 KB = 1024 bytes, 1 MB = 1024 KB
    const mb = bytes / BigInt(1024 * 1024)
    const gb = Number(mb) / 1024

    // return GB value to 2 decimals
    return Number(gb.toFixed(2))
}

export const getValueForDenomination = (params: {
    currency: string
    valueInLowestDenom: number
    discountInLowestDenom?: number
}) => {
    const isHigherDenomCurrency = ["USD", "GBP"].includes(params.currency)
    let displayValue = params.valueInLowestDenom ?? 0
    if (params.discountInLowestDenom) {
        displayValue = params.valueInLowestDenom - params.discountInLowestDenom ?? 0
    }

    const isDivisible = displayValue > 0
    const shouldRaiseDenom = isHigherDenomCurrency && isDivisible
    return shouldRaiseDenom ? displayValue / 100 : 0
}
