import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons"
import {
    Box,
    Button,
    Container,
    Flex,
    IconButton,
    Image,
    Show,
    Spacer,
    Text,
} from "@chakra-ui/react"
import { User } from "@prisma/client"
import dynamic from "next/dynamic"
import NextLink from "next/link"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { MdAddCircle, MdOutlineIosShare } from "react-icons/md"

import { AE } from "../@types/analytics"
import { ESettingsPage } from "../@types/global_types"
import _c from "../configs/constants"
import useLocalStorage from "../hooks/useLocalStorage"
import useWindowSize from "../hooks/useWindowSize"
import { BaseUser } from "../server/dao/user_dao"
import { linkToCreateEvent, linkToCreator, linkToSettingsPage, shareUrl } from "../utils/link_util"
import UserNavbarMenu, { AI_PAGES } from "./UserNavbarMenu"

const ShareModal = dynamic(() => import("./ShareModal"), { ssr: false })
const UsagePillsContainer = dynamic(() => import("./snippets/UsagePillsContainer"))

export interface INavbarProps {
    currentUser: BaseUser
    hideShareButton?: boolean
    transparent?: "start" | "scroll" | "always" | "none"
}

// TODO: make navbar opt-out rather than opt-in
const ALLOWED_PAGES = [
    "/",
    "/404",
    "/host-a-live",
    "/home",
    "/live/create",
    "/course/create",
    "/account/verify",
    "/account/payments",
    "/account/settings",
    "/account/settings/profile",
    "/account/settings/subscription",
    "/account/settings/page-design",
    "/account/subscriptions/cancel",
    "/members",
    "/signup",
    "/login",
    "/feed",
    "/pricing",
    "/creators",
    "/clips",
    "/clips/projects",
    "/clips/projects/[projectId]",
    "/marketing/ai-usage",
]

export const NAVBAR_HEIGHT = "93px"

const Navbar = ({
    currentUser,
    hideShareButton = false,
    transparent = undefined,
    ...rest
}: INavbarProps) => {
    const router = useRouter()
    const isLoggedIn = !!currentUser
    const user = { slug: currentUser?.slug }

    const navbarRef = useRef<HTMLDivElement>()

    const [offset, setOffset] = useState(0)
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
    const [shareModalVisible, setShareModalVisible] = useState(false)

    const { width } = useWindowSize()
    const isMobile = !!(width && width < 768)

    const [snippetGenTestKey, setSgTestKey] = useLocalStorage({
        key: _c.PRODUCT_TESTS.SNIPPET_GENERATOR,
        fallback: false,
    })

    const showNavbar = useMemo(() => {
        return (
            ALLOWED_PAGES.includes(router.asPath.split("?")[0].replace("#", "")) ||
            router.pathname.includes("/[creatorSlug]") ||
            router.pathname.includes("/live/[slug]") ||
            router.pathname.includes("/clips") ||
            router.pathname.includes("/course/[slug]/edit")
        )
    }, [router.pathname, router.asPath])

    const isProfilePreviewPage = useMemo(() => {
        return router.asPath.includes(`${currentUser?.slug}?mode=preview`)
    }, [router.asPath, currentUser])

    const isSnippetsProductView = useMemo(() => {
        if (router.isReady && router.asPath === "/") {
            return false
        }
        if (router.isReady) {
            return (
                AI_PAGES.some((page) => router.pathname.includes(page)) ||
                router.asPath.includes("planId")
            )
        }
        if (typeof window !== "undefined") {
            return !!snippetGenTestKey
        }
    }, [snippetGenTestKey, router])

    const isViewingOwnProfile = useMemo(() => {
        return !!(currentUser && isProfilePreviewPage)
    }, [currentUser, isProfilePreviewPage])

    const isSnippetMode = !!currentUser?.snippetGeneratorTest
    const showEditCta =
        !isSnippetMode && isViewingOwnProfile && isProfilePreviewPage && !isSnippetsProductView
    const showCreateCta =
        !isSnippetMode &&
        !isViewingOwnProfile &&
        !router.asPath.includes("course/create") &&
        !isSnippetsProductView
    const landingPage = isSnippetsProductView ? "/clips" : "/"

    const onMobileMenuClose = useCallback(() => setMobileMenuVisible(false), [])

    const onScroll = useCallback(() => setOffset(window.pageYOffset), [])

    useEffect(() => {
        window.removeEventListener("scroll", onScroll)
        window.addEventListener("scroll", onScroll, { passive: true })
        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    useEffect(() => {
        router.events.on("routeChangeStart", onMobileMenuClose)
        return () => {
            router.events.off("routeChangeStart", onMobileMenuClose)
        }
    }, [])

    if (!showNavbar) {
        return null
    }

    const handleClickShare = async () => {
        const Analytics = (await import("../controllers/analytics_controller")).default
        Analytics.trackEvent(AE.Share_Start, {
            location: "Creator Nav",
            creatorSlug: user!.slug!,
        })
        const shareLink = linkToCreator(user as User, true, true)
        shareUrl({
            title: `${currentUser.slug} - LiveLink`,
            url: shareLink,
            fallback: () => {
                setShareModalVisible(true)
            },
            onShareComplete: (complete) =>
                Analytics.trackEvent(complete ? AE.Share_Finish : AE.Share_Cancelled),
        })
    }

    const handleShareModalClosed = async () => {
        const Analytics = (await import("../controllers/analytics_controller")).default
        Analytics.trackEvent(AE.Share_Finish)
        setShareModalVisible(false)
    }

    const handleRoute =
        (url: string, title: string, newTab = false) =>
        async () => {
            const Analytics = (await import("../controllers/analytics_controller")).default
            Analytics.trackEvent(AE.Navbar_Click, { title, url, newTab })
            if (newTab) {
                window.open(url, "_blank")?.focus()
            } else {
                router.push(url)
            }
        }

    const renderShareButton = () => {
        if (!user.slug || hideShareButton) {
            return null
        }
        const cleanedUrl = `${_c.BASE_URL}/${user.slug}`.replace("https://", "")
        return (
            <Flex
                as="button"
                display={["flex"]}
                onClick={handleClickShare}
                bgColor="black"
                color="white"
                borderRadius="full"
                ml={4}
            >
                <Flex pl="25px" pr="15px" height="50px" alignItems="center" fontWeight="semibold">
                    <Text color="white" display={["none", null, "inline"]}>
                        {cleanedUrl}
                    </Text>
                    <Text color="white" display={["inline", null, "none"]}>
                        Share Link
                    </Text>
                </Flex>
                <IconButton
                    as={Box}
                    colorScheme="primary"
                    bgColor="#343434"
                    _hover={{
                        bgColor: "#444444",
                    }}
                    aria-label="share"
                    isRound={true}
                    boxSize="50px"
                    icon={<MdOutlineIosShare size={21} />}
                />
            </Flex>
        )
    }

    const renderSubscriptionButton = () => {
        if (!showEditCta) {
            return null
        }
        return (
            <Button
                onClick={handleRoute(linkToSettingsPage(ESettingsPage.PageDesign), "Settings")}
                mx={4}
                variant="whiteWithDarkOutline"
            >
                <Image
                    src="/images/edit_black.svg"
                    alt="Edit profile"
                    height="auto"
                    objectFit="contain"
                    mr={2}
                    my={2}
                />
                <Show above="md">
                    <Text>Edit profile</Text>
                </Show>
                <Show below="sm">
                    <Text>Edit</Text>
                </Show>
            </Button>
        )
    }

    const renderCreateEventButton = () => {
        if (!showCreateCta) {
            return null
        }
        return (
            <Show above="md">
                <Button
                    variant="primaryGreen"
                    leftIcon={<MdAddCircle size={20} color="white" />}
                    mr={2}
                    onClick={handleRoute(linkToCreateEvent(currentUser), "Create")}
                >
                    Create an event
                </Button>
            </Show>
        )
    }

    const renderAuthedNavigation = () => {
        const isTransparent = transparent === "always" || (transparent === "start" && offset === 0)
        const borderStyle = !isTransparent ? "1px solid #E8E8E7" : "none"
        return (
            <>
                <Container
                    as="header"
                    minW="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    zIndex={5}
                    position="relative"
                    px={[3, 6, 8]}
                    py={6}
                    height={NAVBAR_HEIGHT}
                    borderBottom={borderStyle}
                    {...rest}
                >
                    <NextLink href={landingPage} passHref legacyBehavior>
                        <Box as="a" flexShrink={0}>
                            <Image
                                src="/images/livelink_logo.svg"
                                alt="LiveLink Logo"
                                width={["32px", "28px"]}
                            />
                        </Box>
                    </NextLink>
                    {currentUser.isCreator && (
                        <>
                            {renderShareButton()}
                            <Box flex={1} />
                            {renderSubscriptionButton()}
                            {renderCreateEventButton()}
                        </>
                    )}
                    {isSnippetsProductView && (
                        <>
                            <Spacer />
                            {currentUser && <UsagePillsContainer currentUser={currentUser} />}
                        </>
                    )}
                    <UserNavbarMenu currentUser={currentUser} />
                </Container>

                {currentUser.isCreator && (
                    <ShareModal
                        title="Share my LiveLink"
                        isOpen={shareModalVisible}
                        onDismiss={handleShareModalClosed}
                        creator={currentUser as User}
                    />
                )}
            </>
        )
    }

    const renderGuestNavigation = () => {
        const isTransparent = transparent === "always" || (transparent === "start" && offset === 0)
        const backgroundStyle = isTransparent
            ? "transparent linear-gradient(180deg, hsla(0, 0%, 0%, 0.7) 0%, #00000000 100%) 0% 0% no-repeat padding-box"
            : "#F7F7F7"

        return (
            <>
                <Flex
                    ref={navbarRef as any}
                    as="header"
                    alignItems="flex-start"
                    justifyContent="center"
                    flexDirection="column"
                    zIndex={5}
                    pos="fixed"
                    top={0}
                    w="100%"
                    background={backgroundStyle}
                    boxShadow={
                        offset > 0 && (transparent === "start" || transparent === "scroll")
                            ? "0px 3px 6px #00000029"
                            : ""
                    }
                    transition="all 0.25s ease"
                    minHeight={NAVBAR_HEIGHT}
                >
                    <Flex mx="auto" alignItems="center" justifyContent="center" w="100%">
                        <NextLink href={landingPage} passHref legacyBehavior>
                            <Box as="a" p={6}>
                                <Image
                                    src="/images/livelink_logo.svg"
                                    alt="LiveLink Logo"
                                    width="28px"
                                />
                            </Box>
                        </NextLink>
                        <Flex grow={1} />
                        <Flex dir="row" alignItems="center">
                            <Box display={["none", "none", "flex"]}>
                                <NextLink
                                    href={
                                        isSnippetsProductView
                                            ? `/login?destination=${encodeURIComponent("/clips")}`
                                            : "login"
                                    }
                                    passHref
                                    legacyBehavior
                                >
                                    <Button
                                        as="a"
                                        variant="primary"
                                        size="lg"
                                        mr={4}
                                        my={6}
                                        boxShadow="none"
                                    >
                                        Sign in
                                    </Button>
                                </NextLink>
                            </Box>
                            {!isMobile && (
                                <NextLink
                                    href={
                                        isSnippetsProductView
                                            ? `/signup?destination=${encodeURIComponent("/clips")}`
                                            : "signup"
                                    }
                                    passHref
                                    legacyBehavior
                                >
                                    <Button
                                        as="a"
                                        size="lg"
                                        mr={[0, 0, 8]}
                                        my={6}
                                        boxShadow="none"
                                        backgroundColor={["black", "black", "white"]}
                                        color={["white", "white", "black"]}
                                        borderRadius={["11px", "11px", "5.25px"]}
                                    >
                                        Get started
                                    </Button>
                                </NextLink>
                            )}
                            <IconButton
                                display={["flex", "flex", "none"]}
                                variant="primary"
                                icon={<HamburgerIcon width="30px" />}
                                onClick={() => setMobileMenuVisible(true)}
                                color="white"
                                aria-label="open navigation menu"
                                border="none"
                                outline="none"
                                boxShadow="none"
                                fontSize="25px"
                                height="42px"
                                width="42px"
                                px="5px"
                                borderRadius="9px"
                                mr={8}
                                ml={4}
                            />
                        </Flex>
                    </Flex>
                </Flex>
                {mobileMenuVisible && renderGuestMobileMenu()}
            </>
        )
    }

    const renderGuestMobileMenu = () => (
        <>
            <Flex
                flexDir="column"
                pos="fixed"
                top="0"
                bottom="0"
                right="0"
                left="0"
                width="100vw"
                height="100vh"
                zIndex={99}
                backgroundColor="rgba(0,0,0,0.4)"
            />
            <Flex
                flexDir="column"
                pos="fixed"
                top="0"
                bottom="0"
                right="0"
                left="auto"
                width="80vw"
                height="100%"
                zIndex={100}
                px="23px"
                py="28px"
                backgroundColor="black"
                alignItems="flex-start"
            >
                <Flex
                    flexDir="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    mb="40px"
                >
                    <Image
                        src="/images/livelink_logo_text_white.svg"
                        alt="LiveLink Logo"
                        width="115px"
                        height="auto"
                        objectFit="contain"
                        mx={2}
                        my={2}
                    />
                    <IconButton
                        display={["flex", "flex", "none"]}
                        variant="primary"
                        icon={<CloseIcon width="30px" />}
                        onClick={onMobileMenuClose}
                        color="white"
                        aria-label="open navigation menu"
                        border="none"
                        outline="none"
                        boxShadow="none"
                        fontSize="15px"
                        height="42px"
                        width="42px"
                        px="5px"
                        borderRadius="9px"
                    />
                </Flex>
                {!isSnippetsProductView && (
                    <Flex
                        flexDir="column"
                        py="23px"
                        width="100%"
                        borderTop="1px solid rgba(255, 255, 255, 0.22)"
                    >
                        <Flex flexDir="row" width="100%" alignItems="center">
                            <Image
                                src="/images/videocam_icon.svg"
                                alt="LiveLink Logo"
                                width="30px"
                                objectFit="contain"
                                mr="14px"
                            />
                            <NextLink href="/signup" passHref legacyBehavior>
                                <Text
                                    color="white"
                                    fontSize="2xl"
                                    fontWeight="600"
                                    cursor="pointer"
                                >
                                    Become a creator
                                </Text>
                            </NextLink>
                        </Flex>
                    </Flex>
                )}
                <Flex
                    flexDir="column"
                    py="23px"
                    width="100%"
                    borderTop="1px solid rgba(255, 255, 255, 0.22)"
                >
                    <Flex flexDir="row" width="100%" alignItems="center">
                        <Image
                            src="/images/login_icon.svg"
                            alt="LiveLink Logo"
                            width="25px"
                            objectFit="contain"
                            mr="18px"
                        />
                        <NextLink
                            href={
                                isSnippetsProductView
                                    ? `/login?destination=${encodeURIComponent("/clips")}`
                                    : "login"
                            }
                            passHref
                            legacyBehavior
                        >
                            <Text color="white" fontSize="2xl" fontWeight="600" cursor="pointer">
                                Sign In
                            </Text>
                        </NextLink>
                    </Flex>
                    {isSnippetsProductView && (
                        <Flex flexDir="row" width="100%" alignItems="center" mt="20px" ml="43px">
                            <NextLink
                                href={`/signup?destination=${encodeURIComponent("/clips")}`}
                                passHref
                                legacyBehavior
                            >
                                <Text
                                    color="white"
                                    fontSize="2xl"
                                    fontWeight="600"
                                    cursor="pointer"
                                >
                                    Sign Up
                                </Text>
                            </NextLink>
                        </Flex>
                    )}
                </Flex>

                <Flex flex={1} flexDir="column" justifyContent="flex-end">
                    <NextLink href="mailto:support@livelink.vip" legacyBehavior>
                        <Text color="#878787" fontSize="lg" fontWeight="400" cursor="pointer">
                            Contact Us
                        </Text>
                    </NextLink>
                </Flex>
            </Flex>
        </>
    )
    if (!isLoggedIn) {
        return renderGuestNavigation()
    }
    return renderAuthedNavigation()
}

export default Navbar
