import React, { ReactNode } from "react"

import { AE } from "../../@types/analytics"
import _c from "../../configs/constants"
import DefaultErrorComponent from "./DefaultErrorPage"

interface ErrorBoundaryProps {
    errorComponent?: ReactNode
    children: ReactNode
}

interface ErrorBoundaryState {
    error: Error | null
}

const SHOW_IN_DEV = false

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { error: null }
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { error }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error(error, errorInfo)
        import("../../controllers/analytics_controller")
            .then((module) => {
                const Analytics = module.default
                Analytics.trackEvent(AE.Application_Error, { message: error?.message })
            })
            .catch(console.error)
    }

    render() {
        if (!!this.state.error) {
            if (_c.isDev && !SHOW_IN_DEV) {
                return this.props.children
            }
            if (this.props.errorComponent) {
                return this.props.errorComponent
            }
            return <DefaultErrorComponent error={this.state.error} />
        }

        return this.props.children
    }
}

export default ErrorBoundary
