import { useEffect, useState } from "react"

import { isServer } from "../utils/browser_util"

interface Size {
    width: number
    height: number
}

const useWindowSize = (args?: { fallback?: { width?: number; height?: number } }): Size => {
    const fallbackWidth = args?.fallback?.width ?? 0
    const fallbackHeight = args?.fallback?.width ?? 0
    const [windowSize, setWindowSize] = useState<Size>({
        width: isServer ? fallbackWidth : window.innerWidth,
        height: isServer ? fallbackHeight : window.innerHeight,
    })
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, [])
    return windowSize
}

export default useWindowSize
