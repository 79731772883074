export enum ECurrency {
    GBP = "GBP",
    USD = "USD",
}

export enum EPurchaseIntent {
    LIVE = "live",
    COURSE = "course",
    RECORDING = "recording",
    COMMUNITY = "community",
    SUBSCRIPTION = "subscription",
}

export enum EPaymentStatus {
    PENDING = "pending",
    SUCCEEDED = "succeeded",
    TRANSFERRED = "transferred",
    PAID_OUT = "paid-out",
    REFUNDED = "refunded",
}

export enum EPaymentType {
    LIVE = "live",
    COURSE = "course",
    RECORDING = "recording",
    SUBSCRIPTION = "subscription",
    SUBSCRIPTION_SPONSORSHIP = "subscription-sponsorship",
}
