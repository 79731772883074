import routes from "../configs/routes"
import {
    GetCheckUserSubscribedCreatorQuery,
    GetCheckUserSubscribedCreatorResponse,
} from "../pages/api/v1/creator/subscription/check"
import {
    CreateSubscriptionCheckoutSessionBody,
    CreateSubscriptionCheckoutSessionResponse,
} from "../pages/api/v1/creator/subscription/checkout"
import {
    GetCreatorSubscriptionPlanQuery,
    GetCreatorSubscriptionPlanResponse,
} from "../pages/api/v1/creator/subscription/plan"
import { GetLLSubscriptionPlanResponse } from "../pages/api/v1/platform-subscription-plan"
import {
    GetPlatformSubscriptionPlansBody,
    GetPlatformSubscriptionPlansResponse,
} from "../pages/api/v1/platform-subscription-plans"
import {
    PostAddMinutesBody,
    PostAddMinutesResponse,
} from "../pages/api/v1/platform-subscription/add-minutes"
import {
    PostApplyDiscountToPlatformSubscriptionBody,
    PostApplyDiscountToPlatformSubscriptionResponse,
} from "../pages/api/v1/platform-subscription/apply-cancellation-discount"
import {
    postCreateMinutesTokenBody,
    postCreateMinutesTokenResponse,
} from "../pages/api/v1/platform-subscription/create-minutes-token"
import {
    PostCreateCustomerPortalSessionBody,
    PostCreateCustomerPortalSessionResponse,
} from "../pages/api/v1/platform-subscription/customer-portal"
import {
    PostSwitchSubscriptionIntervalBody,
    PostSwitchSubscriptionIntervalResponse,
} from "../pages/api/v1/platform-subscription/switch-interval"
import {
    CreatorPurchaseSubscriptionCheckoutSessionBody,
    CreatorPurchaseSubscriptionCheckoutSessionResponse,
} from "../pages/api/v1/purchase-subscription"
import {
    PostCompleteLLSubscriptionPurchaseBody,
    PostCompleteLLSubscriptionPurchaseResponse,
} from "../pages/api/v1/purchase-subscription/complete"
import { handleRequest } from "../utils/network_util"
import {
    PostCancelSubscriptionBody,
    PostCancelSubscriptionResponse,
} from "./../pages/api/v1/platform-subscription/cancel"

const subscriptionService = (hr = handleRequest) => ({
    createSubscriptionCheckoutSession: (
        body: CreateSubscriptionCheckoutSessionBody,
    ): Promise<CreateSubscriptionCheckoutSessionResponse> =>
        hr(routes.CREATE_CREATOR_SUBSCRIPTION_CHECKOUT, body),

    checkUserSubscribedToCreator: (
        query: GetCheckUserSubscribedCreatorQuery,
    ): Promise<GetCheckUserSubscribedCreatorResponse> =>
        hr(routes.GET_CHECK_USER_SUBSCRIBED, query),

    getCreatorSubscriptionPlan: (
        query: GetCreatorSubscriptionPlanQuery,
    ): Promise<GetCreatorSubscriptionPlanResponse> =>
        hr(routes.GET_CREATOR_SUBSCRIPTION_PLAN, query),

    getLLSubscriptionPlans: (
        query: GetPlatformSubscriptionPlansBody,
    ): Promise<GetPlatformSubscriptionPlansResponse> => hr(routes.GET_LL_SUBSCRIPTION_PLANS, query),

    createLLSubscriptionCheckoutSession: (
        query: CreatorPurchaseSubscriptionCheckoutSessionBody,
    ): Promise<CreatorPurchaseSubscriptionCheckoutSessionResponse> =>
        hr(routes.CREATE_LL_SUBSCRIPTION_CHECKOUT, query),

    getLLSubscriptionPlan: (planId: string): Promise<GetLLSubscriptionPlanResponse> =>
        hr(routes.GET_LL_SUBSCRIPTION_PLAN, { planId }),

    postCompletePlatformSubscriptionPurchase: (
        body: PostCompleteLLSubscriptionPurchaseBody,
    ): Promise<PostCompleteLLSubscriptionPurchaseResponse> =>
        hr(routes.POST_COMPLETE_LL_SUBSCRIPTION_PURCHASE, body),

    postCreatePlatformSubscriptionPortalSession: (
        body: PostCreateCustomerPortalSessionBody,
    ): Promise<PostCreateCustomerPortalSessionResponse> =>
        hr(routes.POST_CREATE_CUSTOMER_PORTAL_SESSION, body),

    postCreateAdditionalMinutesToken: (
        body: postCreateMinutesTokenBody,
    ): Promise<postCreateMinutesTokenResponse> => hr(routes.POST_CREATE_MINUTES_TOKEN, body),

    postValidateAndAddMinutes: (body: PostAddMinutesBody): Promise<PostAddMinutesResponse> =>
        hr(routes.POST_ADD_MINUTES, body),

    postApplyCancellationDiscount: (
        body: PostApplyDiscountToPlatformSubscriptionBody,
    ): Promise<PostApplyDiscountToPlatformSubscriptionResponse> =>
        hr(routes.POST_APPLY_CANCELLATION_DISCOUNT, body),

    postSwitchSubscriptionInterval: (
        body: PostSwitchSubscriptionIntervalBody,
    ): Promise<PostSwitchSubscriptionIntervalResponse> =>
        hr(routes.POST_SWITCH_SUBSCRIPTION_INTERVAL, body),

    postCancelSubscription: (
        body: PostCancelSubscriptionBody,
    ): Promise<PostCancelSubscriptionResponse> =>
        hr(routes.POST_CANCEL_SUBSCRIPTION, body),
})

export default subscriptionService
