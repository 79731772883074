import { ChakraProvider } from "@chakra-ui/react"
import type { AppProps } from "next/app"
import type { NextPage } from "next/types"
import { ReactElement, ReactNode, useRef } from "react"

import AppShell from "../components/AppShell"
import Clarity from "../components/Clarity"
import Drip from "../components/Drip"
import GlobalModals from "../components/GlobalModals"
import GoogleTagManager from "../components/GoogleTagManager"
import ScreenTracking from "../components/HOC/ScreenTracking"
import LinkedInInsightTag from "../components/LinkedInInsightTag"
import { BaseMetaTags } from "../components/MetaTags"
import type { IPlatformSubscriptionModalRef } from "../components/subscriptions/PlatformSubscriptionModal"
import ErrorBoundary from "../components/utility/ErrorBoundary"
import _c from "../configs/constants"
import initApp from "../configs/init"
import liveLinkTheme from "../configs/theme"
import { PlatformSubscriptionModalContext } from "../utils/context_util"

import "../styles/global.css"
import "../styles/libraries.css"

export type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
    Component: any
}

initApp()

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const subModalRef = useRef<IPlatformSubscriptionModalRef | null>(null)
    const getLayout = Component.getLayout ?? ((page: any) => page)
    return (
        <ChakraProvider theme={liveLinkTheme} resetCSS={true}>
            <ErrorBoundary>
                <BaseMetaTags />
                <ScreenTracking {...pageProps}>
                    <AppShell {...pageProps}>
                        {/* 
                        We show this modal on several pages and components,
                        to prevent large request overhead per page it is being moved here.
                    */}
                        <PlatformSubscriptionModalContext.Provider value={subModalRef}>
                            <GlobalModals {...pageProps} />
                            {getLayout(<Component {...pageProps} />)}
                        </PlatformSubscriptionModalContext.Provider>
                    </AppShell>
                </ScreenTracking>
                {_c.isProductionOrStaging && <Clarity />}
                {_c.isProduction && <Drip />}
                {_c.isProduction && <GoogleTagManager />}
                {_c.isProduction && <LinkedInInsightTag />}
            </ErrorBoundary>
        </ChakraProvider>
    )
}

export default MyApp
