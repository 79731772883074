import _c from "../configs/constants"
import { isTrackableEnvironment } from "../utils/browser_util"

export class LinkedInFeClient {
    private static getClient = (): any => {
        return (window as any)?.lintrk
    }
    static trackConversion = (conversionId: string) => {
        if (!isTrackableEnvironment()) {
            return
        }
        try {
            LinkedInFeClient.getClient()?.("track", { conversion_id: conversionId })
        } catch (error) {
            console.error(error)
        }
    }
}
