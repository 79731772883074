import routes from "../configs/routes"
import {
    GetCommunityAllActivityQuery,
    GetCommunityAllActivityResponse,
} from "../pages/api/v1/community/[creatorSlug]/all-activity"
import { GetCommunityChannelResponse } from "../pages/api/v1/community/[creatorSlug]/channel/[channelSlug]"
import {
    GetCommunityChannelPostsQuery,
    GetCommunityChannelPostsResponse,
} from "../pages/api/v1/community/[creatorSlug]/channel/[channelSlug]/posts"
import { GetCommunityExistsResponse } from "../pages/api/v1/community/[creatorSlug]/exists"
import { GetCheckCommunityMembershipResponse } from "../pages/api/v1/community/[creatorSlug]/membership/check"
import { PostJoinCommunityResponse } from "../pages/api/v1/community/[creatorSlug]/membership/join"
import { DeleteLeaveCommunityResponse } from "../pages/api/v1/community/[creatorSlug]/membership/leave"
import { GetCommunityMetadataResponse } from "../pages/api/v1/community/[creatorSlug]/metadata"
import {
    PostCreateChannelBody,
    PostCreateChannelResponse,
} from "../pages/api/v1/community/channel/create"
import {
    PostDeleteChannelBody,
    PostDeleteChannelResponse,
} from "../pages/api/v1/community/channel/delete"
import {
    PostPinPostToChannelBody,
    PostPinPostToChannelResponse,
} from "../pages/api/v1/community/channel/pin-post"
import {
    PostUnpinPostFromChannelBody,
    PostUnpinPostFromChannelResponse,
} from "../pages/api/v1/community/channel/unpin-post"
import {
    PostUpdateChannelBody,
    PostUpdateChannelResponse,
} from "../pages/api/v1/community/channel/update"
import {
    PostCreateCommentBody,
    PostCreateCommentResponse,
} from "../pages/api/v1/community/comment/create"
import {
    CreateCommentLikeBody,
    CreateCommentLikeResponse,
} from "../pages/api/v1/community/comment/like"
import {
    PostReplyToCommentBody,
    PostReplyToCommentResponse,
} from "../pages/api/v1/community/comment/reply"
import {
    PostCreateCommunityBody,
    PostCreateCommunityResponse,
} from "../pages/api/v1/community/create"
import {
    PostCreateCommunityFileBody,
    PostCreateCommunityFileResponse,
} from "../pages/api/v1/community/create-file"
import {
    PostInviteMemberBody,
    PostInviteMemberResponse,
} from "../pages/api/v1/community/invite-member"
import {
    PostCreateCommunityLinkBody,
    PostCreateCommunityLinkResponse,
} from "../pages/api/v1/community/link/create"
import { GetCommunityPostResponse } from "../pages/api/v1/community/post/[id]"
import { GetCommunityPostCommentsResponse } from "../pages/api/v1/community/post/[id]/comments"
import { PostCreatePostBody, PostCreatePostResponse } from "../pages/api/v1/community/post/create"
import { PostDeletePostBody, PostDeletePostResponse } from "../pages/api/v1/community/post/delete"
import { PostEditPostBody, PostEditPostResponse } from "../pages/api/v1/community/post/edit"
import { CreatePostLikeBody, CreatePostLikeResponse } from "../pages/api/v1/community/post/like"
import {
    PostUpdateCommunityFileBody,
    PostUpdateCommunityFileResponse,
} from "../pages/api/v1/community/update-file"
import { handleRequest } from "../utils/network_util"

const communityService = (hr = handleRequest) => ({
    getCommunityExists: (creatorSlug: string): Promise<GetCommunityExistsResponse> =>
        hr(routes.GET_COMMUNITY_EXISTS(creatorSlug)),

    getCommunityMetadata: (creatorSlug: string): Promise<GetCommunityMetadataResponse> =>
        hr(routes.GET_COMMUNITY_METADATA(creatorSlug)),

    postCreateCommunity: (body: PostCreateCommunityBody): Promise<PostCreateCommunityResponse> =>
        hr(routes.POST_CREATE_COMMUNITY, body),

    getAllActivity: (
        creatorSlug: string,
        query: GetCommunityAllActivityQuery,
    ): Promise<GetCommunityAllActivityResponse> =>
        hr(routes.GET_COMMUNITY_ALL_ACTIVITY(creatorSlug), query),

    getChannelPosts: (
        creatorSlug: string,
        channelSlug: string,
        query: GetCommunityChannelPostsQuery,
    ): Promise<GetCommunityChannelPostsResponse> =>
        hr(routes.GET_COMMUNITY_CHANNEL_POSTS(creatorSlug, channelSlug), query),

    getChannel: (creatorSlug: string, channelSlug: string): Promise<GetCommunityChannelResponse> =>
        hr(routes.GET_COMMUNITY_CHANNEL(creatorSlug, channelSlug)),

    postJoinCommunityMembership: (creatorSlug: string): Promise<PostJoinCommunityResponse> =>
        hr(routes.POST_JOIN_COMMUNITY_MEMBERSHIP(creatorSlug)),

    deleteLeaveCommunityMembership: (creatorSlug: string): Promise<DeleteLeaveCommunityResponse> =>
        hr(routes.DELETE_LEAVE_COMMUNITY_MEMBERSHIP(creatorSlug)),

    getCheckCommunityMembership: (
        creatorSlug: string,
    ): Promise<GetCheckCommunityMembershipResponse> =>
        hr(routes.GET_CHECK_COMMUNITY_MEMBERSHIP(creatorSlug)),

    getPost: (postId: string): Promise<GetCommunityPostResponse> =>
        hr(routes.GET_COMMUNITY_POST(postId)),

    getComments: (postId: string): Promise<GetCommunityPostCommentsResponse> =>
        hr(routes.GET_COMMUNITY_POST_COMMENTS(postId)),

    postPinPostToChannel: (body: PostPinPostToChannelBody): Promise<PostPinPostToChannelResponse> =>
        hr(routes.POST_PIN_POST_TO_CHANNEL, body),

    unpinPostFromChannel: (
        body: PostUnpinPostFromChannelBody,
    ): Promise<PostUnpinPostFromChannelResponse> => hr(routes.UNPIN_POST_FROM_CHANNEL, body),

    postLikePost: (body: CreatePostLikeBody): Promise<CreatePostLikeResponse> =>
        hr(routes.POST_LIKE_POST, body),

    postLikeComment: (body: CreateCommentLikeBody): Promise<CreateCommentLikeResponse> =>
        hr(routes.POST_LIKE_COMMENT, body),

    postCreatePost: (body: PostCreatePostBody): Promise<PostCreatePostResponse> =>
        hr(routes.POST_CREATE_POST, body),

    deletePost: (body: PostDeletePostBody): Promise<PostDeletePostResponse> =>
        hr(routes.DELETE_POST, body),

    postEditPost: (body: PostEditPostBody): Promise<PostEditPostResponse> =>
        hr(routes.POST_EDIT_POST, body),

    postCreateComment: (body: PostCreateCommentBody): Promise<PostCreateCommentResponse> =>
        hr(routes.POST_CREATE_COMMENT, body),

    postReplyToComment: (body: PostReplyToCommentBody): Promise<PostReplyToCommentResponse> =>
        hr(routes.POST_REPLY_TO_COMMENT, body),

    postCreateChannel: (body: PostCreateChannelBody): Promise<PostCreateChannelResponse> =>
        hr(routes.POST_CREATE_CHANNEL, body),

    postUpdateChannel: (body: PostUpdateChannelBody): Promise<PostUpdateChannelResponse> =>
        hr(routes.POST_UPDATE_CHANNEL, body),

    deleteChannel: (body: PostDeleteChannelBody): Promise<PostDeleteChannelResponse> =>
        hr(routes.DELETE_CHANNEL, body),

    postCreateCommunityFile: (
        body: PostCreateCommunityFileBody,
    ): Promise<PostCreateCommunityFileResponse> => hr(routes.POST_CREATE_COMMUNITY_FILE, body),

    postUpdateCommunityFile: (
        body: PostUpdateCommunityFileBody,
    ): Promise<PostUpdateCommunityFileResponse> => hr(routes.POST_UPDATE_COMMUNITY_FILE, body),

    postCreateCommunityLink: (
        body: PostCreateCommunityLinkBody,
    ): Promise<PostCreateCommunityLinkResponse> => hr(routes.POST_CREATE_LINK, body),

    postInviteMembers: (body: PostInviteMemberBody): Promise<PostInviteMemberResponse> =>
        hr(routes.POST_INVITE_MEMBER, body),
})

export default communityService
