import _c from "../configs/constants"

const splitAtFirstEquals = (str: string): [string, string] => {
    const index = str.indexOf("=")
    return index === -1 ? [str, ""] : [str.slice(0, index), str.slice(index + 1)]
}

export const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split("; ")
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = splitAtFirstEquals(cookie)
        if (cookieName === name) {
            return decodeURIComponent(cookieValue)
        }
    }
    return null
}

export const unsetCookie = (name: string) => {
    const date = new Date()
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
    const expires = "; expires=" + date.toUTCString()
    const domain = "; domain=.livelink.ai"

    document.cookie = name + "=" + _c.COOKIE_STORAGE_UNSET + expires + domain + "; path=/"
}
