import { Flex } from "@chakra-ui/react"
import { withAuthUser } from "next-firebase-auth"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import Footer from "./Footer"
import Navbar, { INavbarProps, NAVBAR_HEIGHT } from "./Navbar"
import SpinnerLoader from "./SpinnerLoader"

export interface IAppShellProps {
    children: any
    currentUser?: any
    appShell?: {
        disable?: boolean
        disableNavbar?: boolean
        disableFooter?: boolean
        disableRouteLevelLoader?: boolean
        navbarOptions?: Partial<INavbarProps>
        backgroundColor?: string
    }
}

const AppShell = ({ children, currentUser, appShell = {} }: IAppShellProps) => {
    const router = useRouter()
    const [loading, setLoading] = useState(false)

    const handleRouteChangeStart = (url: string, { shallow }: any) => !shallow && setLoading(true)
    const handleRouteChangeFinish = () => setLoading(false)

    useEffect(() => {
        router.events.on("routeChangeStart", handleRouteChangeStart)
        router.events.on("routeChangeComplete", handleRouteChangeFinish)

        return () => {
            router.events.off("routeChangeStart", handleRouteChangeStart)
            router.events.off("routeChangeComplete", handleRouteChangeFinish)
        }
    }, [])

    const renderLoader = () => <SpinnerLoader />

    if (appShell.disable) {
        return (
            <>
                {(!loading || appShell?.disableRouteLevelLoader) && children}
                {loading && !appShell?.disableRouteLevelLoader && renderLoader()}
            </>
        )
    }

    return (
        <>
            {!appShell.disableNavbar && (
                <Navbar currentUser={currentUser} {...(appShell?.navbarOptions ?? {})} />
            )}

            <Flex
                as="main"
                direction="column"
                minHeight="100vh"
                minWidth="100vw"
                backgroundColor={appShell?.backgroundColor ?? "#F7F7F7"}
                marginTop={
                    !appShell?.disableNavbar && appShell?.navbarOptions?.transparent === "always"
                        ? `-${NAVBAR_HEIGHT}`
                        : "0px"
                }
            >
                {(!loading || appShell?.disableRouteLevelLoader) && children}
                {loading && !appShell?.disableRouteLevelLoader && renderLoader()}
            </Flex>
            {!appShell.disableFooter && <Footer />}
        </>
    )
}

export default withAuthUser()(AppShell) as any
