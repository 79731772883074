import Script from "next/script"

const LinkedInInsightTag = () => (
    <>
        <Script
            id="linkedin"
            dangerouslySetInnerHTML={{
                __html: `
                _linkedin_partner_id = "${process.env.LINKEDIN_PARTNER_ID}";
                window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                (function(l) {
                if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                window.lintrk.q=[]}})(window.lintrk)
            `,
            }}
        />
        <Script
            async
            type="text/javascript"
            src="https://snap.licdn.com/li.lms-analytics/insight.min.js"
            strategy="afterInteractive"
        />

        <noscript>
            <img
                height="1"
                width="1"
                style={{ display: "none" }}
                alt=""
                src={`https://px.ads.linkedin.com/collect/?pid=${process.env.LINKEDIN_PARTNER_ID}&fmt=gif`}
            />
        </noscript>
    </>
)

export default LinkedInInsightTag
