import { ArrowBackIcon } from "@chakra-ui/icons"
import {
    BoxProps,
    Modal as ChakraModal,
    ComponentWithAs,
    HStack,
    IconButton,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
} from "@chakra-ui/react"
import { useRouter } from "next/router"
import React, { FC, ReactNode, useEffect, useState } from "react"

// --------------------------------------------------------
// Types
// --------------------------------------------------------

export interface IModalProps {
    isOpen: boolean
    children: ReactNode | ReactNode[]
    overlayStyles?: ComponentWithAs<"div">
    containerStyles?: BoxProps
    contentContainerStyles?: BoxProps
    onDismiss: () => void
    canDismiss?: boolean
    blockScrollOnMount?: boolean
    accessibility?: {
        role?: string
        "aria-labelledby"?: string
        "aria-describedby"?: string
    }
    hideDismissBtn?: boolean
    showBackBtn?: boolean
    onClickBack?: () => void
    size?: "sm" | "md" | "lg" | "xl" | "4xl"
}

export const Modal: FC<IModalProps> = ({
    isOpen,
    children,
    onDismiss,
    onClickBack,
    canDismiss = false,
    hideDismissBtn,
    showBackBtn,
    overlayStyles = {},
    contentContainerStyles = {},
    blockScrollOnMount = false,
    accessibility,
    size = "md",
}) => {
    const router = useRouter()
    const [isVisible, setVisibility] = useState<boolean>(false)

    useEffect(() => {
        const isErrorPage = router.pathname === "404" || router.pathname === "500"
        if (!isVisible && isOpen && !isErrorPage) {
            setVisibility(true)
        } else if (isVisible && !isOpen) {
            setVisibility(false)
        } else if (isErrorPage) {
            setVisibility(false)
        }
    }, [isVisible, isOpen, router.pathname])

    if (!isOpen) {
        return null
    }

    const extraAttributes = {
        ...accessibility,
    }

    return (
        <ChakraModal
            onClose={onDismiss!}
            isOpen={isOpen}
            blockScrollOnMount={blockScrollOnMount}
            closeOnOverlayClick={canDismiss}
            size={size}
            isCentered
        >
            <ModalOverlay {...overlayStyles} />
            <ModalContent
                borderRadius="2xl"
                bgColor="white"
                {...contentContainerStyles}
                {...extraAttributes}
            >
                <HStack>
                    {showBackBtn && (
                        <IconButton
                            onClick={onClickBack}
                            _hover={{ cursor: "pointer" }}
                            background="white"
                            aria-label="go back"
                            margin="8px"
                            boxSize="28px"
                            p="5px"
                            as={ArrowBackIcon}
                            isRound
                            boxShadow="lg"
                        />
                    )}
                    {canDismiss && !hideDismissBtn && (
                        <ModalCloseButton
                            borderRadius="3xl"
                            background="#ffffffc2"
                            boxShadow="lg"
                            zIndex={2}
                            color="gray.700"
                        />
                    )}
                </HStack>
                {children}
            </ModalContent>
        </ChakraModal>
    )
}

export default Modal
