export enum ESettingsPage {
    Profile = "profile",
    PageDesign = "page-design",
    Subscription = "subscription",
}

export enum EContentAccessType {
    NONE = "NONE",
    PURCHASED = "PURCHASED",
    PURCHASE_EXPIRED = "PURCHASE_EXPIRED",
    SUBSCRIBED = "SUBSCRIBED",
}

export enum EQueryTabsView {
    UPCOMING_LIVES = "upcoming-lives",
    RECORDED_LIVES = "recorded-lives",
    UPCOMING_COURSES = "upcoming-courses",
    RECORDED_COURSES = "recorded-courses",
    DRAFTS = "drafts",
}

export enum EAnimation {
    brandLoading = "brandLoading",
    confetti = "confetti",
    broadcastDot = "broadcastDot",
    notFound404 = "notFound404",
    spinnerBlob = "spinnerBlob",
    dripLoading = "dripLoading",
    blackLock = "blackLock",
}