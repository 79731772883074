import timezones from "../configs/timezones.json"

//--------------------------------------------------------
// Types
// --------------------------------------------------------

export type TTimezoneEntry = {
    group: string
    zones: TTimezoneEntryDict[]
}

export type TTimezoneEntryDict = {
    value: string
    name: string
}

//--------------------------------------------------------
// Utils
// --------------------------------------------------------

export const GLOBAL_TIMEZONE_FALLBACK: TTimezoneEntryDict = {
    value: "Europe/London",
    name: "London",
}

export const VALID_TIMEZONE_CODES = timezones.reduce(
    (previous: any[], { zones }: TTimezoneEntry) => {
        return previous.concat(zones.map(({ value }) => value))
    },
    [],
)

export const TIMEZONES: TTimezoneEntryDict[] = timezones
    .reduce((previous: any[], { zones }: TTimezoneEntry) => {
        return previous.concat(zones)
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name))
