import routes from "../configs/routes"
import { GetCreatorProfileResponse } from "../pages/api/v1/creator/[creatorSlug]"
import {
    GetCreatorDashboardQuery,
    GetCreatorDashboardResponse,
} from "../pages/api/v1/creator/members"
import { handleRequest } from "../utils/network_util"

const creatorService = (hr = handleRequest) => ({
    getCreatorProfile: (slug: string): Promise<GetCreatorProfileResponse> =>
        hr(routes.GET_CREATOR_PROFILE(slug)),

    getAllCreatorMembers: (query: GetCreatorDashboardQuery): Promise<GetCreatorDashboardResponse> =>
        hr(routes.GET_CREATOR_MEMBERS, query),
})

export default creatorService
