import { AE } from "./../@types/analytics"
import _c from "../configs/constants"
import { isTrackableEnvironment } from "../utils/browser_util"

export class GTMFEClient {
    private static getClient = (): any => {
        return (window as any)?.dataLayer
    }
    static trackEvent = (event: AE, additionalData: Record<any, any> = {}) => {
        if (!isTrackableEnvironment()) {
            return
        }
        try {
            GTMFEClient.getClient()?.push({ event: event, ...additionalData })
        } catch (error) {
            console.error(error)
        }
    }
}
