import routes from "../configs/routes"
import {
    GetLiveCheckRecordingPurchaseQuery,
    GetLiveCheckRecordingPurchaseResponse,
} from "../pages/api/v1/live/check-recording-purchase"
import {
    GetRecordingPurchaseQuery,
    GetRecordingPurchaseResponse,
} from "../pages/api/v1/live/get-recording-purchase"
import {
    CreateRecordingCheckoutSessionBody,
    CreateRecordingCheckoutSessionResponse,
} from "../pages/api/v1/recording/checkout"
import {
    SendRecordingTokenBody,
    SendRecordingTokenResponse,
} from "../pages/api/v1/live/send-recording-token"
import {
    PostSendRecordingToSubscriberBody,
    PostSendRecordingToSubscriberResponse,
} from "../pages/api/v1/recording/send-to-subscriber"
import {
    PostTriggerRecordingExpiryBody,
    PostTriggerRecordingExpiryResponse,
} from "../pages/api/v1/recording/trigger-expiry"
import { GetLiveRecordingByIdResponse } from "../pages/api/v1/recording/[id]"
import { handleRequest } from "../utils/network_util"

const recordingService = (hr = handleRequest) => ({
    getRecordingById: (id: string): Promise<GetLiveRecordingByIdResponse> =>
        hr(routes.GET_LIVE_RECORDING_BY_ID(id)),

    getLiveCheckRecordingPurchase: (
        query: GetLiveCheckRecordingPurchaseQuery,
    ): Promise<GetLiveCheckRecordingPurchaseResponse> =>
        hr(routes.GET_LIVE_CHECK_RECORDING_PURCHASE, query),

    getLiveRecordingPurchase: (
        query: GetRecordingPurchaseQuery,
    ): Promise<GetRecordingPurchaseResponse> => hr(routes.GET_LIVE_RECORDING_PURCHASE, query),

    createRecordingCheckoutSession: (
        body: CreateRecordingCheckoutSessionBody,
    ): Promise<CreateRecordingCheckoutSessionResponse> =>
        hr(routes.CREATE_RECORDING_CHECKOUT_SESSION, body),

    resendRecordingToken: (body: SendRecordingTokenBody): Promise<SendRecordingTokenResponse> =>
        hr(routes.SEND_RECORDING_TOKEN, body),

    triggerRecordingPurchaseExpiry: (
        body: PostTriggerRecordingExpiryBody,
    ): Promise<PostTriggerRecordingExpiryResponse> => hr(routes.TRIGGER_RECORDING_EXPIRY, body),

    sendRecordingToSubscriber: (
        body: PostSendRecordingToSubscriberBody,
    ): Promise<PostSendRecordingToSubscriberResponse> =>
        hr(routes.SEND_RECORDING_TO_SUBSCRIBER, body),
})

export default recordingService
