import routes from "../configs/routes"
import {
    GetPaymentsReceivedQuery,
    GetPaymentsReceivedResponse,
} from "../pages/api/v1/payment/received"
import { handleRequest } from "../utils/network_util"

const paymentService = (hr = handleRequest) => ({
    getPaymentsReceived: (query: GetPaymentsReceivedQuery): Promise<GetPaymentsReceivedResponse> =>
        hr(routes.GET_PAYMENTS_RECEIVED, query),
})

export default paymentService
