import NextLink from "next/link"
import { forwardRef, Link, LinkProps } from "@chakra-ui/react"

const SiteLink = forwardRef<LinkProps, "a">(({ href, children, ...rest }: any, ref: any) => {
    return (
        <NextLink href={href} passHref legacyBehavior>
            <Link ref={ref} {...rest}>{children}</Link>
        </NextLink>
    );
})

export default SiteLink